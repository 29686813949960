import { Button } from "components/ui/button";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import DelightIcon from "assets/delight.svg";
import FrustrationIcon from "assets/frustration.svg";
import happyIcon from "assets/happy.png";
import sadIcon from "assets/sad.png";
import { useState } from "react";
import { detect } from "detect-browser";
// import { AdminEmail } from "constant";
// import { feedback } from "services/api";

const Feedback = () => {
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [emoji, setEmoji] = useState("");
  const [loader, setLoader] = useState(false);
  const options = [
    {
      name: "Delight",
      img: DelightIcon,
      text: "Delight",
      width: "50px",
    },
    {
      name: "Happy",
      img: happyIcon,
      text: "Happy",
      width: "60px",
      marginTop: "-5px",
      height: "55px",
    },
    {
      name: "Sad",
      img: sadIcon,
      text: "Sad",
      width: "60px",
      height: "50px",
    },
    {
      name: "Frustration",
      img: FrustrationIcon,
      text: "Frustration",
      width: "50px",
    },
  ];

  const sendFeedback = async () => {
    if (!emoji) return;
    setLoader(true);

    const { name: browserName, version }: any = detect();
    const payload = {
      type: "email",
      // email: `${AdminEmail}`,
      endpoint: "feedback",
      subject: "Feedback from: Ultrapass Google",
      message: `\n \nUrl: ${
        window.location.href
      } \n \nFeeling: ${emoji}  \n \nUserAgent: ${
        window.navigator.userAgent
      } \n\n
      OS: ${window.navigator.platform} \n\n 
      Browser: ${JSON.stringify(
        browserName
      )}, Browser Version: ${JSON.stringify(version)}
      `,
    };
    // await feedback(payload);
    setLoader(false);
    navigateWithQueryParams("/");
  };

  const getEmojiColorStyle = (currentEmoji: string, isImage = false) => {
    if (emoji && emoji !== currentEmoji)
      return {
        opacity: 0.5,
      };
    if (isImage) {
      switch (currentEmoji) {
        case "Frustration":
          return {
            filter:
              "invert(30%) sepia(84%) saturate(3048%) hue-rotate(0deg) brightness(99%) contrast(83%)",
          };
        case "Delight":
          return {
            filter:
              "invert(30%) sepia(84%) saturate(3048%) hue-rotate(120deg) brightness(99%) contrast(83%)",
          };
      }
    } else {
      switch (currentEmoji) {
        case "Happy":
          return {
            color: "#FFD700",
          };
        case "Sad":
          return {
            color: "#FFA500",
          };
      }
    }
  };
  return (
    <Layout>
      <div className="px-10 py-8  max-md:p-[20px]">
        <div className="flex justify-between relative">
          <BackButton />
          <CloseButton />
        </div>
        <div className="mt-5">
          <h2 className="text-[28px] font-[500]">
          How would you rate your experience?
          </h2>
        </div>
        <div className="flex justify-between w-[80%] m-auto mt-[20px]">
          {options.map((opt: any, i) => (
            <div
              onClick={() => setEmoji(opt.name)}
              className="w-[80px] cursor-pointer"
            >
              <img
                src={opt.img}
                alt=""
                style={{
                  ...getEmojiColorStyle(opt.name, true),
                  marginTop: opt?.marginTop,
                }}
                className={`w-[${opt?.width}] h-[${
                  opt?.height || "50px"
                }] m-auto mb-[10px]`}
              />
              <p color={"#000"}>
              {opt.text}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div
        style={{ boxShadow: "0px -2px 8px 0px rgba(0, 0, 0, 0.08)" }}
        className="text-left h-[140px] absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt-4 pb-4 max-md:h-[unset] max-md:rounded-0 max-md:p-[20px]"
      >
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-2 hover:opacity-90 hover:bg-primary"
            onClick={() => {
              sendFeedback();
            }}
            disabled={loader}
          >
            Return to homepage
          </Button>
        </div>
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            onClick={() => navigateWithQueryParams("/")}
          >
            No Thanks
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default Feedback;
