import { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
// import {
//   getTokenDetails,
//   getTransactionResult,
//   verifyUserOidc,
//   completeCibaAuth,
//   getCibaTokenDetails
// } from "@privateid/ultra-web-sdk-alpha";
import faceID from "Animations/5-Verify/JSONs/Face_ID.json";
import { Label } from "components/ui/label";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { UserContext } from "context/userContext";
import { issueCredentials } from "services/vc-dock";
import {
  getFirstRequirement,
  getUrlParameter,
} from "utils";
import { ECHO, TELE, VERIFY_STATUS_MESSAGE } from "constant";
import config from "config";
import Layout from "common/layout";
import { useNavigation } from "utils/onNavigate";
import { verifyCall } from "services/api";
import { useSearchParams } from "react-router-dom";

type Props = {};
let loaded = false;
const Waiting = (props: Props) => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const [percentage, setPercentage] = useState(0);
  const [displayGoBack, setDisplayGoBack] = useState(false);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
   const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("token");
  const goNext = async () => {
    const verifyResult: any = await verifyCall(getUrlParameter("token", "") ||
      ""
    );

    console.log("verifyResult?", verifyResult);
    if (verifyResult?.userApproved === "true") {
      if (verifyResult?.status === "pending") {
        context?.setSuccessMessage(VERIFY_STATUS_MESSAGE.PENDING);
      } else if (verifyResult?.status === "failed") {
        context?.setSuccessMessage(VERIFY_STATUS_MESSAGE.FAILED);
      } else if (verifyResult?.status === null || verifyResult?.status === "") {
        context?.setSuccessMessage(VERIFY_STATUS_MESSAGE.NULL);
      }
      issueVC(sessionId, true)
      navigateToNextPage("", "/success", "/waiting");
    } else {
      console.log("Navigate???");
      navigateWithQueryParams("/failed");
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (percentage <= 99) {
        setPercentage((prevPercentage) => prevPercentage + 1);
        if (percentage >= 99) {
          clearInterval(intervalId);
        }
      }
    }, 50);
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [percentage]);

  useEffect(() => {
    if (!loaded) {
      loaded = true;
      goNext();
    }
    console.log(66, { loaded });
    setTimeout(() => {
      if ([ECHO, TELE]?.includes(config.clientConfig.productGroupId)) {
        if (pageConfig) {
          navigateToNextPage("", "/address", "/waiting");
        } else {
          navigateWithQueryParams("/address");
        }
      } else if (!loaded) {
        // onVerify();
      }
    }, 2000);
  }, []);

  const getRequirements = async (requirement: any) => {
    const requirementStep = await getFirstRequirement(requirement, context);
    loaded = false;
    switch (requirementStep) {
      case "requestSSN9":
        return pageConfig
          ? navigateToNextPage("", "/ssn", "/waiting")
          : navigateWithQueryParams("/ssn");
      case "requireResAddress":
        return pageConfig
          ? navigateToNextPage("", "/address", "/waiting")
          : navigateWithQueryParams("/address");
      case "requestScanID":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-intro", "/waiting")
          : navigateWithQueryParams("/drivers-licence-intro");
      default:
        break;
    }
  };

  const issueVC = async (sessionId: any, fullInformation: boolean) => {
    try {
      await issueCredentials(sessionId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };

  return (
    <Layout>
      <div className="p-10 max-md:p-[20px]">
        <div
          className="h-[620px] flex justify-center items-center flex-col"
          style={{
            backgroundImage: `url("/verifying-bg.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="flex justify-center items-center">
            <Lottie
              loop={true}
              autoplay={true}
              animationData={faceID}
              style={{ height: 200, width: 200 }}
            />
          </div>
          <Label className="text-primary text-[28px] font-[700] mt-6">
            {percentage}%
          </Label>
          <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-3">
            {displayGoBack
              ? "Please Go Back To Desktop"
              : "Please wait a sec, we’re verifying your identity.."}
          </Label>
        </div>
      </div>
    </Layout>
  );
};

export default Waiting;