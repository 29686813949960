import { PASSKEY_BASE_URL } from "../constant";

export const generateRegistrationOptions = async (
  token: string,
  hash?: string
) => {
  try {
    const response = await fetch(
      PASSKEY_BASE_URL + `/${token}/passkey/generate-registration-options`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ hash: hash }),
      }
    );
    const opts = await response.json();
    return opts;
  } catch (error) {
    return error;
  }
};

export const verifyRegistration = async ({ attResp, token }: any) => {
  const verificationResp = await fetch(
    PASSKEY_BASE_URL + `/${token}/passkey/verify-registration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(attResp),
    }
  );

  const verificationJSON = await verificationResp.json();
  return verificationJSON;
};

export const generateAuthenticationOptions = async (
  token: string,
  hash?: string
) => {
  try {
    const response = await fetch(
      PASSKEY_BASE_URL + `/${token}/passkey/generate-authentication-options`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ hash: hash }),
      }
    );
    const opts = await response.json();
    return opts;
  } catch (error) {
    return error;
  }
};

export const verifyAuthentication = async ({ asseResp, token }: any) => {
  const verificationResp = await fetch(
    PASSKEY_BASE_URL + `/${token}/passkey/verify-authentication`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(asseResp),
    }
  );

  const verificationJSON = await verificationResp.json();
  return verificationJSON;
};

export const passkeyGenerateRegistrationOptions = async (token: string) => {
  try {
    const response = await fetch(
      PASSKEY_BASE_URL + `/${token}/passkey/generate-registration-options`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const opts = await response.json();
    return opts;
  } catch (error) {
    return error;
  }
};

export const passkeyVerifyRegistration = async ({ attResp, token }: any) => {
  const verificationResp = await fetch(
    PASSKEY_BASE_URL + `/${token}/passkey/verify-registration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ data: attResp }),
    }
  );

  const verificationJSON = await verificationResp.json();
  return verificationJSON;
};

export const dropVerifyRegistration = async ({ attResp, token }: any) => {
  const verificationResp = await fetch(
    PASSKEY_BASE_URL + `/v2/passkey/oidc/verify-registration`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        data: attResp,
        options: { authenticatorAttachment: "cross-platform" },
      }),
    }
  );

  const verificationJSON = await verificationResp.json();
  return verificationJSON;
};