import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import PasskeyBg from "assets/passkey-bg.svg";
import PasskeyErrorIcon from "assets/passkey-error.png";
import Layout from "../../layout";
import BackButton from "../../components/backButton";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import {
  startRegistration,
  startAuthentication, browserSupportsWebAuthn,
} from "@simplewebauthn/browser";
import {
  generateAuthenticationOptions,
  verifyAuthentication,
  verifyRegistration,
} from "services/passkey";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import { useToast } from "components/ui/use-toast";
import CloseButton from "common/components/closeButton";
import Stepper from "common/faceScanningIntro/Stepper";
import { useNavigation } from "utils/onNavigate";
import { extractRoute, getDeviceHash, getOrganizationFromURL, getUrlParameter } from "utils";

type Props = {};
let loadded = false
function LoginPasskey(Props: Props) {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const projectName = getOrganizationFromURL();
  const { toast } = useToast();
  const token = getUrlParameter("token", "") || "";
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const [supportsPasskey, setSupportsPasskey] = useState(false);
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption('');
    context?.setPageConfiguration(pageConfiguration);
  };
  useEffect(() => {
    const checkWebAuthnSupport = async () => {
      try {
        if ("PublicKeyCredential" in window) {
          const supportsPasskey = browserSupportsWebAuthn();
          setSupportsPasskey(supportsPasskey);
          await onGeneratePasskey();
          loadded = false;
        } else {
          // WebAuthn is not supported
          setSupportsPasskey(false);
        }
      } catch (error) {
        console.error("Error checking WebAuthn support:", error);
        setSupportsPasskey(false);
      }
    };
    if (!loadded) {
      loadded = true;
      checkWebAuthnSupport();
    }
  }, []);
  const onGeneratePasskey = async () => {
    try {
      const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
      const hash = await getDeviceHash().then((hash) => hash);
      const response = await generateAuthenticationOptions(token, hash);
      if (response?.challenge) {
        let asseResp;
        try {
          asseResp = await startAuthentication(response);
        } catch (error) {
          console.log({ error });
        }
        const verificationJSON = await verifyAuthentication({ asseResp, token });
        if (verificationJSON?.verified) {
          loadded = false;
          if (["checkoutVc", "loginVc"]?.includes(context.loginOption)) {
            if (pageConfig) {
              navigateToNextPage("", "/vc-proof", "/login-passkey");
            } else {
              navigateWithQueryParams("/vc-proof");
            }
          } else {
            context?.setSuccessMessage("Authentication successful!");
            if (projectName === "charlie") {
              navigateToNextPage("", "/store-os", "/face-login");
              return;
            }
            if (pageConfig) {
              navigateToNextPage("", "/success", "/login-passkey");
            } else {
              navigateWithQueryParams("/success");
            }
          }
        } else {
          loadded = false;
          localStorage.removeItem("user");
          toast({
            variant: "destructive",
            description: "There was some issue authenticating with passkey.",
          });
          loadPageConfiguration();
          if (pageConfig) {
            navigateToNextPage("", "/", "/login-passkey");
          } else {
            navigateWithQueryParams("/", { token: undefined });
          }
        }
      } else {
        loadded = false;
        localStorage.removeItem("user");
        toast({
          variant: "destructive",
          description: "Passkey not found.",
        });
        loadPageConfiguration();
        if (pageConfig) {
          navigateToNextPage("", "/", "/login-passkey");
        } else {
          navigateWithQueryParams("/", { token: undefined });
        }
      }
    } catch (e) {
      loadded = false;
      localStorage.removeItem("user");
      toast({
        variant: "destructive",
        description: "There was some issue authenticating with passkey.",
      });
      loadPageConfiguration();
      if (pageConfig) {
        navigateToNextPage("", "/", "/login-passkey");
      } else {
        navigateWithQueryParams("/", { token: undefined });
      }
    }
  };
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <div className="px-10 py-8 max-md:p-[20px]">
            <div className="flex justify-between relative mb-[20px]">
              <BackButton />
              <div className="flex items-center justify-center w-full">
                <Stepper step={1} loginSteps={true} />
              </div>
              <CloseButton />
            </div>
            <div className="mt-[-10px]">
              <Label className="text-[28px] font-[500] text-primaryText">
                {pageConfig?.title || "Use your FIDO2® Passkey to Sign In"}
              </Label>
            </div>
            {!supportsPasskey ? (
              <div className="mt-2">
                <img
                  src={PasskeyErrorIcon}
                  alt="PasskeyErrorIcon"
                  className="w-[100px] m-auto block mt-[7rem]"
                />
                <p className="text-[20px] font-[400] mt-[30px]">
                  Your browser is not currently supported. Please use a{" "}
                  <a
                    href="https://passkeys.dev/device-support/#matrix"
                    className="color-[#] text-primary"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    recommended browser
                  </a>{" "}
                  or learn more{" "}
                  <a
                    href="https://passkeys.dev/device-support/"
                    className="color-[#] text-primary"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    here
                  </a>
                  .
                </p>
              </div>
            ) : (
              <div className="text-center overflow-auto h-[550px] mt-2 p-1 max-md:h-[unset]">
                <div className="ps-8 pe-8">
                  <Label className="text-[14px] font-[400] text-secondaryText">
                    {pageConfig?.subheading ||
                      `Sign in to ${window.location.origin} using your Passkey`}
                  </Label>
                </div>
                <div className="mt-2">
                  <img
                    src={pageConfig?.image || PasskeyBg}
                    alt="PasskeyBg"
                    className="h-[240px] w-full rounded-[12px]"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="text-left h-[80px] absolute w-full bottom-0 rounded-b-[24px] ps-10 pe-10 pt- pb-4">
        <div></div>
      </div>
    </Layout>
  );
}

export default LoginPasskey;
