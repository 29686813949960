import { Layout } from "../index";
import { useContext, useEffect, useState } from "react";
import Phone from "assets/phone.png";
import useWasm from "hooks/useWasm";
import { getOrganizationFromURL, getStatusFromUser, getUrlParameter, isMobile } from "utils";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  CameraConfig,
  ENROLL_CANVAS_RESOLUTION,
  ERROR,
  PUBLIC_KEY,
  SECURITY_CHALLENGE,
  SUCCESS,
} from "constant";
import useCamera from "hooks/useCamera";
import useCameraPermissions from "hooks/useCameraPermissions";
import ProveLogo from "assets/proveLogo.png";
import PhoneInput from "common/components/phoneInput";
import useFaceLoginWithLivenessCheck from "hooks/useFaceLoginWithLiveness";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { getUserFromSession } from "services/api";
import Lottie from "lottie-react";
import completedCheck from "Animations/4-Capture-successfully/JSON/confetti.json";
import MainLogo from "../../assets/login/logo-main.png";
import FaceAnimationProve from "common/animateCircle/faceScanAnimationProve";

const rendererSettings = {
  preserveAspectRatio: "xMaxYMin slice",
};

const ProveLogin = () => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const [deviceId, setDeviceId] = useState<string>();
  const [phone, setPhone] = useState<string>("");
  const [showPhone, setShowPhone] = useState(true);
  const projectName = getOrganizationFromURL();
  const { isCameraGranted }: any = useCameraPermissions(() => {});
  const { navigateToNextPage } = useNavigation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [minimizeCamera, setMinimizeCamera] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isCompletedPredict, setIsCompletedPredict] = useState(false);
  const logo = context?.configuration?.logo || MainLogo;
  // Camera and Wasm init
  const onCameraFail = () => {
    //   cameraFail?.();
  };
  const cameraReady = () => {
    //   onCameraReady?.();
    faceLoginWithLiveness();
  };
  const documentScan = false;
  const url = process.env.REACT_APP_WASM_API_URL || "";
  const { ready: wasmReady, wasmStatus } = useWasm(
    getUrlParameter("token", "") || "",
    url,
    PUBLIC_KEY
  );
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );
  useEffect(() => {
    if (device) {
      setDeviceId(device);
    }
  }, [device]);
  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };
  useEffect(() => {
    handleWasmLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);
  const onStatus = () => {};
  const onPredictFail = () => {};

  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginResponseStatus,
    faceLoginInputImageData,
    faceLoginSuccess
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onStatus,
    50,
    false,
    onPredictFail
  );

  useEffect(() => {
    if (faceLoginSuccess) {
      setIsCompletedPredict(true);
    }
  }, [faceLoginSuccess]);

  useEffect(() => {
    if (isCompletedPredict && !showPhone) {
      setCompleted(true);
      context.setUser({
        ...context.user,
        uuid: "",
        guid: "",
      });
      handelLoginResponse(faceLoginResponseStatus);
    }
  }, [isCompletedPredict, showPhone]);

  useEffect(() => {
    if (phone?.length >= 12) {
      setShowPhone(false);
    }
  }, [phone]);

  const handelLoginResponse = async (result: any) => {
    if ([0, 10].includes(result)) {
      localStorage.setItem("uuid", JSON.stringify(result || {}));
      const payload = {
        uuid: "",
        guid: "",
        checkVC: false,
      };
      // This is for kantara flow
      if (
        context?.configuration?.isKantara &&
        window.location.pathname === "/predict-scan"
      ) {
        context.setUser({
          ...context.user,
          // enrollImageData: faceLoginInputImageData,
        });
        navigateToNextPage();
        return;
      }
      if (["aadhaar", "digi-in"].includes(projectName)) {
        localStorage.setItem("user", JSON.stringify({ uuid: "user" }));
        handleNextStep();
        return;
      }
      const data: any = await getUserFromSession(context.tokenParams);
      if (data?.data?.level === ERROR || data?.data?.statusCode === 404) {
        context.setFailedMessage(AUTHENTICATION_FAILED);
        navigateWithQueryParams("/failed");
        context.setUser({
          ...context.user,
          data,
        });
        localStorage.setItem("user", JSON.stringify(data.user || {}));
        return;
      }
      const user =
        data?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
      if (user._id) {
        const userStatus = getStatusFromUser(data.status);
        if (userStatus === SUCCESS) {
          context.setUser({
            ...context.user,
            ...user,
          });
          localStorage.setItem("user", JSON.stringify(user || {}));
          handleNextStep();
        } else {
          context.setFailedMessage(ACCOUNT_NOT_APPROVED);
          navigateWithQueryParams("/failure");
        }
      }
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      navigateWithQueryParams("/failed");
    }
  };

  const handleNextStep = () => {
    switch (context.loginOption) {
      case "passkey":
        return pageConfig
          ? navigateToNextPage("", "/login-passkey", "/face-login")
          : navigateWithQueryParams("/login-passkey");
      case "loginPin":
        context?.setSuccessMessage("Authentication successful!");
        if (pageConfig) {
          navigateToNextPage("", "/verify-pin", "/face-login");
        } else {
          navigateWithQueryParams("/verify-pin");
        }
        break;
      case "loginDl":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-back-intro", "/face-login")
          : navigateWithQueryParams("/drivers-licence-back-intro");
      case "loginVc":
        return pageConfig
          ? navigateToNextPage("", "/login-passkey", "/face-login")
          : navigateWithQueryParams("/login-passkey");
      // case "checkoutVc":
      //   return onAuthenticatePasskey()
      case "accountRecovery":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-back-intro", "/face-login")
          : navigateWithQueryParams("/drivers-licence-back-intro");
      case SECURITY_CHALLENGE:
        return pageConfig
          ? navigateToNextPage("", "/challenge", "/face-login")
          : navigateWithQueryParams("/challenge");
      default:
        // localStorage.setItem("user", JSON.stringify(user || {}));
        context?.setSuccessMessage("Authentication successful!");
        if (projectName === "charlie") {
          navigateToNextPage("", "/store-os", "/face-login");
          return;
        }
        if (pageConfig) {
          navigateToNextPage("", "/success", "/face-login");
        } else {
          navigateWithQueryParams("/success");
        }
        break;
    }
  };

  useEffect(() => {
    if (
      typeof faceLoginResponseStatus === "number" &&
      isCameraGranted
    ) {
      // setMinimizeCamera(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faceLoginResponseStatus, isCameraGranted]);

  const handleAnimationComplete = (state: string) => {
    if (state === "start") {
      // setStartAnimation(true);
    } else if (state === "completed") {
      setCompleted(true);
      context.setUser({
        ...context.user,
        uuid: '',
        guid: '',
      });
      handelLoginResponse(faceLoginResponseStatus);
    }
  };

  return (
    // <Layout removeBorder={true} removeHeight={true}>
    <div className="bg-[#0c121c] backdrop-blur-md max-md:h-[calc(100vh_-80px)] relative">
      {/* <img
        src={ProveLogo}
        alt="ProveLogo"
        className="webLogo absolute top-[10px] z-[99999] max-md:right-[10px] right-[unset] left-[10px] max-md:left-[unset] max-md:hidden"
      /> */}
      <div className="max-md:w-[100%] w-[30%] m-auto h-[100vh] relative mb-[0px] max-md:h-[calc(100vh_-80px)]">
        {/* <img
          src={Phone}
          alt="Phone"
          className="w-[100%] h-[100%] mt-[-30px] max-md:mt-[-10px]"
        /> */}
        <div className="bg-[#000] w-[100%] absolute top-[0px] left-[0px] max-md:w-[100%] h-[100vh] max-md:top-[0px] max-md:h-[calc(100vh_-80px)] flex flex-col items-center justify-center">
          {showPhone && (
            <div className="absolute top-[0px] left-[0px] z-[99999] bg-[#0000006e] backdrop-blur-md h-[100%] w-[100%] rounded-[2px] flex items-center justify-center">
              <div className="relative w-[90%] max-md:w-[90%]">
                <div className="mt-[-20px] provePhoneInput">
                  <PhoneInput
                    setValue={setPhone}
                    placeholder={"Phone Number"}
                    ariaLabel={"Phone Number"}
                    phone={phone}
                  />
                </div>
                <div
                  onClick={() => {
                    setShowPhone(false);
                    // faceLoginWithLiveness();
                  }}
                  className="bg-primary text-[#fff] w-[100px] h-[40px] mt-[10px] ml-auto rounded-[3px] flex justify-center items-center cursor-pointer text-[15px]"
                >
                  Sign in
                </div>
              </div>
            </div>
          )}

          <img
            src={logo}
            alt="Logo"
            className="w-[130px] absolute top-[10px] z-[99999] max-md:right-[40%] right-[unset] left-[40%] max-md:left-[unset]"
          />

          {completed ? (
            <div className="absolute h-full w-full top-0 bg-[#0c121c] flex items-center justify-center">
              <Lottie
                loop={false}
                autoplay={true}
                animationData={completedCheck}
                style={{
                  height: isMobile ? 320 : "260px",
                }}
                rendererSettings={isMobile ? {} : rendererSettings}
              />
            </div>
          ) : (
              <>
                <FaceAnimationProve
                    isCircle={minimizeCamera}
                    isScanned={completed}
                    handleAnimationComplete={handleAnimationComplete}
                    fullScreen={true}
                >
                  <video
                      id="userVideo"
                      muted
                      autoPlay
                      playsInline
                      className={`w-full h-full face-camera object-cover`}
                  />
                </FaceAnimationProve>
                <div className="text-[20px] absolute bottom-28 w-full bg-black bg-opacity-70 text-white text-center py-2 rounded-lg">
                  {faceLoginWithLivenessMessage}
                </div>
                <p className="text-[16px] absolute bottom-[10px] text-left px-[10px] z-[999999] text-[#fff]">
                  By clicking Sign-in you agree to our terms of use and that you
                  have read the Privacy Policy.{" "}
                </p>
              </>
          )}
        </div>
      </div>
    </div>
      // </Layout>
  );
};

export default ProveLogin;
