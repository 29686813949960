import { Button } from "components/ui/button";
import useCameraPermissions from "hooks/useCameraPermissions";
import * as React from "react";
import cameraIcon from "assets/cameraIcon.svg";
import deniedCameraIcon from "assets/deniedCamera.svg";
import { AllowCameraModal } from "common/components/allowCameraModal";
import { CameraConfig, ENROLL_CANVAS_RESOLUTION, PUBLIC_KEY } from "constant";
import useCamera from "hooks/useCamera";
import { getUrlParameter, isMobile } from "utils";
import useWasm from "hooks/useWasm";
import { Loader2 } from "lucide-react";
import { Label } from "components/ui/label";
import frontDlImage from "assets/frontDl.svg";
import backDlImage from "assets/backDl.svg";

interface componentNameProps {
  onCameraReady: () => void;
  message?: string;
  progress?: number;
  documentScan?: boolean;
  frontDl?: boolean;
  subHeading?: string;
}

const Camera = (props: componentNameProps) => {
  const { progress, documentScan, subHeading } = props;
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [overlayImage, setOverlayImage] = React.useState(true);
  const { isCameraGranted, state }: any = useCameraPermissions(() => {});
  const url = process.env.REACT_APP_WASM_API_URL || "";
  const { ready: wasmReady, wasmStatus } = useWasm(
    getUrlParameter("token", "") || "",
    url,
    PUBLIC_KEY
  );
  const onCameraFail = () => {
    // cameraFail?.();
  };
  const cameraReady = () => {
    props?.onCameraReady?.();
  };
  //   Init Camera hook
  const canvasResolution =
    !isMobile && !documentScan ? ENROLL_CANVAS_RESOLUTION : null;
  const enrollOneFaProgress: any = progress
    ? Math.min(Math.ceil(progress), 100)
    : 0;
  const { ready, init, device, devices } = useCamera(
    CameraConfig?.elementId,
    documentScan ? "back" : (CameraConfig?.mode as any),
    CameraConfig?.requireHD,
    onCameraFail,
    documentScan,
    canvasResolution
  );

  //   Load wasm
  const handleWasmLoad = () => {
    if (!wasmReady && wasmStatus.isChecking) return;
    if (wasmReady && !wasmStatus.isChecking && wasmStatus.support) {
      if (!ready) {
        init();
      } else if (isCameraGranted && ready) {
        cameraReady();
      }
    }
    if (!wasmReady && !wasmStatus.isChecking && !wasmStatus.support) {
      onCameraFail();
    }
  };


  React.useEffect(() => {
    handleWasmLoad();
    setTimeout(() => {
      setOverlayImage(false);
    }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wasmReady, ready, wasmStatus]);
  const renderVideo = () => {
    return (
      <div className={`bg-[#0b101b] w-full h-full`}>
        {!ready && (
          <div className="absolute h-full w-full left-0 top-0 z-50 flex items-center justify-center">
            <Loader2 className="animate-spin h-[55px] w-[55px] text-[#fff]" />
          </div>
        )}
        {overlayImage && documentScan && (
          <div className="w-full h-full flex items-center justify-center absolute top-[50%] left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
            <img
              src={props.frontDl ? frontDlImage : backDlImage}
              alt="dl_image"
              className="w-[70%] filter invert-[100%]"
            />
          </div>
        )}
        <video
          id="userVideo"
          muted
          autoPlay
          playsInline
          className={`w-full h-full 
                    object-cover ${!documentScan && "face-camera"}`}
        />
      </div>
    );
  };
  // console.log(isCameraGranted, "isCameraGranted", state);

  const renderContent = () => {
    if (!isCameraGranted && state === "prompt") {
      return (
        <div className="flex items-center justify-center w-full h-[86vh]">
          <div className="w-full flex flex-col justify-center items-center border border-[#ccc] p-[40px] rounded-[30px] bg-[#161b25] h-[400px]">
            <img src={cameraIcon} alt="cameraIcon" />
            <p className="text-[28px] text-white max-w-[350px] text-center">
              Your browser will request access to your camera
            </p>
          </div>
        </div>
      );
    } else if (!isCameraGranted && state === "denied") {
      return (
        <div className="flex items-center justify-center w-full h-[86vh]">
          <div className="w-full flex flex-col justify-center items-center border border-[#ccc]  p-[40px] rounded-[30px] bg-[#161b25] h-[400px]">
            <img
              src={deniedCameraIcon}
              alt="cameraIcon"
              className="max-w-[70px]"
            />
            <p className="text-[28px] text-white max-w-[350px]">
              Can not access camera
            </p>
            <p className="text-[16px] text-placeholder max-w-[350px] font-[200]">
              Access to your camera has been denied.
            </p>
            <Button
              className="max-w-[140px] w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
              onClick={() => setIsModalOpen(!isModalOpen)}
            >
              Allow camera
            </Button>
          </div>
        </div>
      );
    } else if (isCameraGranted && ["granted", "prompt"]?.includes(state)) {
      return (
        <div className="h-[86vh] w-full max-md:pt-[0px] p-[20px] min-[768px]:h-[unset]">
          {ready && (
            <div className="flex-grow relative z-[9999999] mt-[30px]">
              <Label className="text-[25px] font-[500] text-white block text-center">
                {props?.message}
              </Label>
              <Label className="text-[15px]  font-[400] text-white block text-center opacity-80">
              {subHeading || 'Look directly into the camera while we do the magic'}
              </Label>
            </div>
          )}
          <div className="absolute top-[0px] left-0 w-full h-full">
            {renderVideo()}
          </div>
          <div className=" absolute w-[80%] bottom-5 max-md:left-[40px] max-md:bottom-[100px]">
            <div className="h-3 relative max-w-xl rounded-full overflow-hidden ">
              <div className="w-full h-full bg-gray-50 opacity-20 absolute"></div>
              <div
                id="bar"
                className={`transition-all ease-out duration-1000 h-full bg-white relative`}
                style={{width: `${enrollOneFaProgress}%`}}
              ></div>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <>
      {renderContent()}{" "}
      <AllowCameraModal
        open={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
      />
    </>
  );
};

export default Camera;
