import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import useFaceDeletionWithLivenessCheck from "hooks/useFaceDeletionWithLiveness";
import { getOrganizationFromURL } from "utils";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { useNavigation } from "utils/onNavigate";
import {
  generateAuthenticationOptions,
  verifyAuthentication,
} from "services/passkey";
import { startAuthentication } from "@simplewebauthn/browser";
import { useToast } from "components/ui/use-toast";

function FaceDeletion() {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const projectName = getOrganizationFromURL();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [completed, setCompleted] = useState(false);
  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };

  const { toast } = useToast();

  const {
    faceDeleteWithLiveness,
    faceLoginWithLivenessMessage,
    faceDeleteResponseStatus,
    faceLoginSuccess,
  } = useFaceDeletionWithLivenessCheck(onStatus, 50, false, onPredictFail);

  const onSuccess = () => {
    context.setUser({
      ...context.user,
      uuid: "",
      guid: "",
    });
    handelDeleteResponse(faceDeleteResponseStatus);

    if (window.self !== window.top) {
      window.parent.postMessage({ type: "SESSION_COMPLETE" }, "*");
      return;
    }
  };

  const handelDeleteResponse = async (result: any) => {
    if ([0, 10].includes(result)) {
      localStorage.setItem("uuid", JSON.stringify(result || {}));
      localStorage.removeItem("uuid");
      localStorage.removeItem("user");
      sessionStorage.removeItem("uuid");
      sessionStorage.removeItem("user");
      window.location.href = "/";
      toast({
        variant: "success",
        description: "User Successfully Deleted",
      });
      return;
    } else {
      context.setFailedMessage(
        "Face deletion failed. Please try again or contact support"
      );
      navigateWithQueryParams("/failed");
    }
  };

  useEffect(() => {
    if (projectName === "register-i9") {
      context?.setLoginOption("faceLogin");
    }
  }, [projectName]);

  useEffect(() => {
    if (faceLoginSuccess) {
      setCompleted(true);
    }
  }, [faceLoginWithLivenessMessage]);

  const onCameraReady = () => {
    if (context?.loginOption === "face-login-mobile") return;
    faceDeleteWithLiveness();
  };

  return (
    <Layout removeHeight={!isCameraGranted}>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative max-md:p-0">
          <BackButton />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || "Biometrics never leave this device"}
          </div>
        </div>
        <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center relative">
          <CameraComponent
            faceCamera={true}
            onCameraReady={onCameraReady}
            progress={faceLoginSuccess ? 100 : 0}
            message={faceLoginWithLivenessMessage}
            onSuccess={onSuccess}
            attempt={typeof faceDeleteResponseStatus === "number" ? 1 : 0}
            scanCompleted={completed}
            onCameraSwitch={() => faceDeleteWithLiveness()}
          />
        </div>
      </div>
    </Layout>
  );
}

export default FaceDeletion;