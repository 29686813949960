import { useContext, useState } from "react";
import { predict } from "@privateid/ultra-web-sdk-alpha";
import { getStatusMessage } from "@privateid/ultra-web-sdk-alpha/dist/utils";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "context/userContext";
import { useToast } from "components/ui/use-toast";

// Type definitions for the callback result
type PredictResult = {
  guid?: string;
  puid?: string;
  antispoof_status: number;
  face_validation_status: number;
  mf_token?: string;
  api_response?: {
    success: boolean
  }
};

// Type definition for the hook's parameters
interface UseMultiframePredictProps {
  onSuccess: () => void;
}

let mf_count = 5;
let loop = true;
let max_spoof_attempt = 5;
let spoof_attempt = 0;
const useMultiframePredict = ({ onSuccess }: UseMultiframePredictProps) => {
  const context: any = useContext(UserContext);
  const configuration = context?.configuration;
  const [multiframePredictMessage, setPredictMessage] = useState<string>("");
  const [multiframePredictAntispoofPerformed, setPredictAntispoofPerformed] =
    useState<string>("");
  const [multiframePredictAntispoofStatus, setPredictAntispoofStatus] =
    useState<number | null>();
  const [multiframePredictValidationStatus, setPredictValidationStatus] =
    useState<number | null>();
  const [progress, setProgress] = useState<number>(0);
  const [faceLoginSuccess, setFaceLoginSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const { toast } = useToast();  
  const mf_count_override = searchParams.get("mf_count_signin")
    ? Number(searchParams.get("mf_count_signin"))
    : mf_count;
  // Type definition for the callback function
  const callback = async (result: PredictResult | any) => {
    console.log("predict callback hook result:", result);
    const token = result.mf_token;
    if (token) {
      setProgress((p) => {
        if (p >= 100) return 100;
        return p + 100 / mf_count_override;
      });
    } else {
      setProgress(0);
    }
    if (result?.api_response?.success === false) {
      toast({
        variant: "destructive",
        description: "User not registered",
      });
      setTimeout(() => {
        window.location.href = "/"
      }, 1500)
      return;
    }
    if (result?.api_response?.success) {
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
      onSuccess();
      setFaceLoginSuccess(true);
      loop = false
    } else if (result?.antispoof_status === 1) {
      spoof_attempt++;
      setProgress(0);
      if (spoof_attempt >= max_spoof_attempt) {
        alert("Spoof attempt exceeded");
        console.log("Spoof attempt exceeded", spoof_attempt);
        spoof_attempt = 0;
        setProgress(0);
        multiframePredictUserOneFa({ mf_token: '' });
        // window.location.href = "/";
      } else {
        setPredictAntispoofStatus(result.antispoof_status);
        setPredictValidationStatus(result.face_validation_status);
        setPredictMessage(getStatusMessage(result.face_validation_status));
        multiframePredictUserOneFa({ mf_token: token });
      }
    } else if (result.guid && result.puid) {
      setPredictAntispoofStatus(result.antispoof_status);
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
    } else {
      setPredictAntispoofStatus(result.antispoof_status);
      setPredictValidationStatus(result.face_validation_status);
      setPredictMessage(getStatusMessage(result.face_validation_status));
      if (loop) {
        multiframePredictUserOneFa({ mf_token: token });
      }
    }
  };

  // Type definition for the function parameters
  interface MultiframePredictUserOneFaProps {
    mf_token?: string;
  }

  const multiframePredictUserOneFa = async ({
    mf_token,
  }: MultiframePredictUserOneFaProps) => {
    setPredictAntispoofPerformed("");

    await predict({
      callback,
      config: {
        disable_predict_mf: false,
        mf_count_override,
        skipAntispoof:
          searchParams.get("skipAntispoof") === "true" ||
          !configuration?.liveness ||
          false,
        mf_token: mf_token || "",
        // threshold_user_too_far: 0.1,
        allowed_results: [10],
        anti_spoofing_threshold: 0.8,
        threshold_user_too_far: 0.15,
        threshold_profile_predict: 0.65,
      },
    });
  };

  return {
    multiframePredictUserOneFa,
    multiframePredictMessage,
    multiframePredictAntispoofPerformed,
    multiframePredictAntispoofStatus,
    multiframePredictValidationStatus,
    progress,
    faceLoginSuccess
  };
};

export default useMultiframePredict;