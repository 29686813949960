import { useEffect, useState } from "react";
import { scanBackDocument } from "@privateid/ultra-web-sdk-alpha";
import { CANVAS_SIZE } from "../utils";

const useBackScanDocument = (onSuccess: (e: any) => void) => {
  const [scannedCodeData, setScannedCodeData] = useState<any>({});
  const [isFound, setIsFound] = useState(false);

  // base64 image
  const [inputImageBase64, setInputImageBase64] = useState(null);
  const [croppedDocumentBase64, setCroppedDocumentBase64] = useState(null);
  const [croppedBarcodeBase64, setCroppedBarcodeBase64] = useState(null);
  const [barcodeStatusCode, setBarcodeStatusCode] = useState(null);
  const [docStatusCode, setDocStatusCode] = useState(null);
  const [isMobileDetected, setIsMobileDetected] = useState(3);

  const documentCallback = async (result: any) => {
    console.log("Document Result:", result);
    setBarcodeStatusCode(
      result.doc_validation_status || result.barcode_detection_status
    );
    if (result?.is_mobile) {
      setIsMobileDetected(1);
      if (result.barcode_detection_status === 0 && result.barcode_data) {
        setScannedCodeData(result?.barcode_data);
        setBarcodeStatusCode(result?.barcode_detection_status);
        setDocStatusCode(result?.doc_validation_status);
        setCroppedBarcodeBase64(result?.cropped_barcode_base64);
        setCroppedDocumentBase64(result?.cropped_document_base64);
        setIsFound(true);

        // onSuccess({
        //   barcodeData: result?.barcode_data,
        //   isMobile: result?.is_mobile,
        //   croppedDocument: result?.cropped_document_base64,
        //   croppedBarcode: result?.cropped_barcode_base64,
        // });
        return;
      } else {
        setBarcodeStatusCode(
          result.doc_validation_status || result.barcode_detection_status
        );
        doScanBackDocument();
      }
    } else {
      setIsMobileDetected(0);
      if (
        result.doc_validation_status === 0 &&
        result?.cropped_document_base64
      ) {
        setScannedCodeData(result?.barcode_data);
        setDocStatusCode(result?.doc_validation_status);
        setBarcodeStatusCode(result?.barcode_detection_status);
        setCroppedBarcodeBase64(result?.cropped_barcode_base64);
        setCroppedDocumentBase64(result?.cropped_document_base64);
        setIsFound(true);
        return;
      } else {
        setBarcodeStatusCode(
          result.doc_validation_status || result.barcode_detection_status
        );
        doScanBackDocument();
      }
    }
  };

  // onSuccess Callback
  useEffect(() => {
    if (isMobileDetected === 1) {
      if (
        (isFound &&
          croppedDocumentBase64 &&
          croppedBarcodeBase64 &&
          scannedCodeData) ||
        (isFound && croppedBarcodeBase64 && scannedCodeData)
      ) {
        console.log("SUCCESS????!!!!");
        onSuccess({
          inputImage: "",
          croppedDocument: croppedDocumentBase64
            ? "data:image/png;base64," + croppedDocumentBase64
            : "",
          croppedBarcode: "data:image/png;base64," + croppedBarcodeBase64,
          barcodeData: scannedCodeData,
          isMobile: isMobileDetected,
        });
      }
    } else {
      if (isFound && croppedDocumentBase64) {
        console.log("SUCCESS????!!!!");
        onSuccess({
          inputImage: "",
          croppedDocument: croppedDocumentBase64
            ? "data:image/png;base64," + croppedDocumentBase64
            : "",
          croppedBarcode: "",
          barcodeData: scannedCodeData,
          isMobile: isMobileDetected,
        });
      }
    }
  }, [
    isFound,
    croppedDocumentBase64,
    croppedBarcodeBase64,
    scannedCodeData,
    isMobileDetected,
  ]);

  const doScanBackDocument = async (canvasSize?: any) => {
    const canvasObj = canvasSize ? CANVAS_SIZE?.[canvasSize] : {};
    scanBackDocument({
      callback: documentCallback,
      config: {
        document_scan_barcode_only: true,
        document_auto_rotation: true,
      },
    });
  };

  return {
    scanBackDocument: doScanBackDocument,
    scannedCodeData,
    isFound,
    croppedDocumentBase64,
    croppedBarcodeBase64,
    barcodeStatusCode,
    docStatusCode,
    isMobileDetected,
  };
};

export default useBackScanDocument;