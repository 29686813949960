import { useContext } from "react";
import Lottie from "lottie-react";
import NotApprovedImage from "assets/not-approved.svg";
import failIdCard from "Animations/5-Verify/JSONs/Fail-ID-Card.json";
import failPassport from "Animations/5-Verify/JSONs/Fail-Passport.json";
import failSelfie from "Animations/5-Verify/JSONs/Fail-selfie.json";
import { ACCOUNT_NOT_APPROVED, AUTHENTICATION_FAILED } from "constant";
import { UserContext } from "context/userContext";
import Layout from "common/layout";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { useNavigate } from "react-router-dom";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { extractRoute } from "utils";
import {useNavigateWithQueryParams} from "../../../utils/navigateWithQueryParams";

type Props = {
  heading?: string;
  buttonLabel?: string;
};

function FailureScreen(Props: Props) {
  const navigate = useNavigate();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption('');
    context?.setPageConfiguration(pageConfiguration);
  };
  const getMiddleImage = (type: string) => {
    switch (type) {
      case "idCard":
        return (
          <Lottie
            loop={false}
            animationData={failIdCard}
            autoplay={true}
            style={{ height: 200, width: 200 }}
          />
        );
      case "passport":
        return (
          <Lottie
            loop={false}
            autoplay={true}
            animationData={failPassport}
            style={{ height: 200, width: 200 }}
          />
        );
      case "selfie":
        return (
          <Lottie
            animationData={failSelfie}
            loop={false}
            autoplay={true}
            style={{ height: 200, width: 200 }}
          />
        );
      case AUTHENTICATION_FAILED:
        return <img src={NotApprovedImage} alt="" className="w-[80px]" />;
      case ACCOUNT_NOT_APPROVED:
        return <img src={NotApprovedImage} alt="" className="w-[80px]" />;
      default:
        return (
          <Lottie
            loop={false}
            animationData={failIdCard}
            autoplay={true}
            style={{ height: 200, width: 200 }}
          />
        );
    }
  };
  return (
    <>
      <Layout>
        <div className="h-full px-10 py-8 flex justify-between items-center flex-col max-md:p-[20px]">
          <div className="w-full">
            <div className="flex justify-between relative">
              {/* <BackButton /> */}
              <CloseButton />
            </div>
            <div className="flex justify-center items-center mt-[0px]">
              {getMiddleImage(
                pageConfig?.message ||
                  context?.failedMessage ||
                  AUTHENTICATION_FAILED
              )}
            </div>
            <Label className="text-[28px] font-[500] text-primaryText w-[95%] mt-10">
              {pageConfig?.message
                ? pageConfig?.message
                : context?.failedMessage === "passport"
                ? `Oops! Your ${Props.heading} is not valid, please rescan it.`
                : context?.failedMessage || ACCOUNT_NOT_APPROVED}
            </Label>
          </div>
          <div
            className={`text-left h-[154px] absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
          >
            <div>
              {/* <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() => {navigate(-1)}}
              >
                {pageConfig?.buttonText || Props.buttonLabel}
              </Button> */}
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() => {
                  loadPageConfiguration();
                  window.location.href = '/'
                }}
              >
                {pageConfig?.secondaryButton || "Return to homepage"}
              </Button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default FailureScreen;
