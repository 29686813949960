// @ts-nocheck
import InputMask from "react-input-mask";
import { Input } from "../../../components/ui/input";
import demoFlag from "../../../assets/login/demo-flag.svg";

interface PhoneInputProps {
  setValue: (phone: string) => void;
  handleFocus?: () => void;
  errorMessage?: string;
  placeholder?: string;
  ariaLabel?: string;
  phone?: string;
  error?: any;
  autoFocus?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
  setValue,
  handleFocus,
  errorMessage,
  placeholder,
  ariaLabel,
  phone,
  error,
  autoFocus
}) => {
    const beforeMaskedStateChange = ({ nextState }: any) => {
        let newState = nextState;
        let value = nextState?.enteredString;
        if (value?.length > 8 && value?.charAt(0) === '0') {
            value = value.slice(1);
            newState = { ...newState, value };
        }

        return newState;
    };


    const handleInputChange = (e: any) => {
      let inputValue = e?.target?.value;
      // Remove all non-numeric characters
      const sanitizedInput = inputValue?.replace(/[^0-9]/g, "");
      // Get the last 10 digits
      const phoneNumber = sanitizedInput?.slice(-10);
    if (sanitizedInput.length <= 10) {
      setValue(phoneNumber);
    }
  };
  return (
    <div className="relative">
      <InputMask
        mask="999-999-999999"
        name={"phone"}
        maskPlaceholder={null}
        alwaysShowMask={true}
        beforeMaskedStateChange={beforeMaskedStateChange}
        onChange={handleInputChange}
        onFocus={handleFocus}
        value={phone}
      >
        <Input
          type="tel"
          name="phone"
          placeholder={placeholder || "Enter your phone number *"}
          className={`"mt-5 pt-[8px] ps-[3.3rem] h-[56px] rounded-[4px] placeholder:text-placeholder focus:!border-[#e2e8f0] placeholder:font-normal`}
          maxLength={15}
          error={errorMessage}
          aria-label={ariaLabel}
          autoFocus={autoFocus}
          autoComplete="off"
        />
      </InputMask>
      <div className="flex absolute top-4 left-2 flagWrap">
        <img
          src={demoFlag}
          alt="country-icon"
          className="font-[400] text-primaryText"
        />
        <span className="ml-[3px] text-[0.875rem] mt-[1.7px]">+1</span>
      </div>
    </div>
  );
};

export default PhoneInput;
