// import { useContext, useEffect } from "react";
// import Lottie from "lottie-react";
// import { UserContext } from "context/userContext";
// import successJson from "Animations/5-Verify/JSONs/Success.json";
// import Layout from "common/layout";
// import { Label } from "components/ui/label";
// import { Button } from "components/ui/button";
// import { useNavigate } from "react-router-dom";
// import { extractRoute, isMobile } from "utils";
// import BackButton from "common/components/backButton";
// import CloseButton from "common/components/closeButton";
// import { useNavigation } from "utils/onNavigate";
// import { OidcContext } from "context/OidcContext";

// type Props = {
//   heading?: string;
// };

// function Success(Props: Props) {
//   const context: any = useContext(UserContext);
//   const pageConfig: any = context?.pageConfiguration;
//   const navigate = useNavigate();
//   const { navigateToNextPage } = useNavigation();
//   const oidcContext = useContext(OidcContext);
//   const loadPageConfiguration = () => {
//     const currentRouteIndex = context?.configuration?.appPages?.findIndex(
//       (item: any) => item?.currentRoute === "/"
//     );
//     context?.setPageIndex(currentRouteIndex);
//     const pageConfiguration = extractRoute(
//       context?.configuration,
//       currentRouteIndex
//     );
//     context?.setLoginOption("");
//     context?.setPageConfiguration(pageConfiguration);
//   };
//   const onSignout = () => {
//     if (context?.configuration?.isKantara && pageConfig?.type !== "completed") {
//       if (pageConfig?.type === "login") {
//         sessionStorage.clear();
//         navigateToNextPage("", "/", "/success");
//       } else {
//         navigateToNextPage();
//       }
//       return;
//     }
//     if (pageConfig?.type === "completed") {
//       if (pageConfig?.buttonText === "Sign Out") {
//         sessionStorage.clear();
//       }
//       localStorage.removeItem("uuid");
//       loadPageConfiguration();
//       navigate("/");
//       return;
//     }
//     localStorage.removeItem("uuid");
//     loadPageConfiguration();
//     if (pageConfig) {
//       if (pageConfig?.buttonText === "Sign Out") {
//         sessionStorage.clear();
//       }
//       navigateToNextPage("", "/", "/success");
//     } else {
//       navigate("/");
//     }
//   };
//   const onSecondaryButton = () => {
//     localStorage.removeItem("uuid");
//     loadPageConfiguration();
//     if (pageConfig) {
//       navigate("/");
//     } else {
//       navigate("/");
//     }
//   };
//   useEffect(() => {
//     context.setLoginOption("");
//   }, []);
//   return (
//     <Layout>
//       <div className="h-full px-10 py-8 flex justify-between items-center flex-col max-md:p-[20px]">
//         <div className="w-full">
//           <div className="flex justify-between relative">
//             {/* <BackButton /> */}
//             <CloseButton />
//           </div>
//           <div className="flex justify-center items-center mb-[-4rem]">
//             <Lottie
//               loop={false}
//               autoplay={true}
//               animationData={successJson}
//               style={{
//                 height: isMobile ? 350 : 400,
//                 width: isMobile ? 380 : 400,
//               }}
//             />
//           </div>
//           <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
//             {pageConfig?.message ||
//               context?.successMessage ||
//               Props.heading ||
//               "Success! Your account is created"}
//           </Label>
//           {context?.configuration?.ciba && (
//             <>
//               <div>
//                 <Label className="text-[14px] font-[500] text-primaryText w-[90%] mt-[-4rem] max-md:mt-[-4rem]">
//                   {`ID Token: ${oidcContext?.cibaIdToken?.slice(
//                     oidcContext?.cibaIdToken?.length - 20
//                   )}`}
//                 </Label>
//               </div>
//               <div>
//                 <Label className="text-[14px] font-[500] text-primaryText w-[90%] mt-[-4rem] max-md:mt-[-4rem]">
//                   {`Access Token: ${oidcContext?.cibaAccessToken}`}
//                 </Label>
//               </div>
//             </>
//           )}
//           {pageConfig?.type === "completed" && (
//             <p className="text-[14px] font-[400]">
//               No biometric data was retained. This account will expire after two years of inactivity.
//             </p>
//           )}
//         </div>
//         <div
//           className={`text-left ${
//             pageConfig?.secondaryButton ? "h-[154px]" : "h-[100px]"
//           } absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
//         >
//           <div>
//             <Button
//               className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
//               onClick={() => onSignout()}
//             >
//               {pageConfig?.buttonText || "Sign out"}
//             </Button>
//             {pageConfig?.secondaryButton && (
//               <Button
//                 className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
//                 onClick={() => {
//                   if (context?.configuration?.isKantara) {
//                     onSecondaryButton();
//                   } else if (pageConfig) {
//                     loadPageConfiguration();
//                     navigateToNextPage("", "/", "/success");
//                   } else {
//                     navigate("/login-options");
//                   }
//                 }}
//               >
//                 {pageConfig?.secondaryButton || "Return to options"}
//               </Button>
//             )}
//           </div>
//         </div>
//       </div>
//     </Layout>
//   );
// }

// export default Success;

import { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import { UserContext } from "context/userContext";
import successJson from "Animations/5-Verify/JSONs/Success.json";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import Layout from "common/layout";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";

import { useSearchParams } from "react-router-dom";
import { extractRoute, getOrganizationFromURL, isMobile } from "utils";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "../../utils/navigateWithQueryParams";

import { getWebhookPayload } from "services/api";
import { getCredentialDetails } from "services/vc-dock";

type Props = {
  heading?: string;
};

type WebhookResult = {
  guid?: string;
  puid?: string;
};

function Success(Props: Props) {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const projectName = getOrganizationFromURL();

  const [searchParams] = useSearchParams();
  const [credentialData, setCredentialData] = useState<any>();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const uuid = localStorage.getItem("uuid");
  const sessionId = searchParams.get("token");
  const [webhookResult, setWebhookResult] = useState<WebhookResult>({});
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/success"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption("");
    context?.setPageConfiguration(pageConfiguration);
  };

  const onSignout = () => {
    onPostMessage();
    sessionStorage.removeItem("uuid");
    sessionStorage.removeItem("user");
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    window.location.href = "/";
    return;
  };

  const buttonHandler = () => {
    if (pageConfig?.buttonText) {
      // window.location.href = "/";
      onSignout();
    } else {
      onSignout();
    }
  };

  const onSecondaryButton = () => {
    sessionStorage.removeItem("uuid");
    loadPageConfiguration();
    if (pageConfig) {
      navigateToNextPage("", "/", "/success");
    } else {
      navigateWithQueryParams("/", { token: undefined });
    }
  };
  useEffect(() => {
    context.setLoginOption("");
  }, []);

  const onPostMessage = () => {
    const returnPayload = {
      completed: true,
    };

    window.parent.postMessage(returnPayload, "*");
  };

  useEffect(() => {
    if (uuid && (context?.userData || credentialData)) {
      onPostMessageUUid();
    }
  }, [uuid, context?.userData, credentialData]);

  const onPostMessageUUid = () => {
    const userData = context?.userData;
    const credentials = credentialData?.credential?.credentialSubject;
    const returnPayload = {
      type: "PrivateID",
      identityInformation: {
        documentType: credentials?.document_type || "drivers_license",
        issuingCountry: userData?.CountryRegion || credentials?.country,
        issuingState: credentials?.issuing_state || "",
        documentNumber:
          userData?.DocumentNumber || credentials?.document_number,
        issuedDate:
          userData?.DateOfIssue || credentials?.issued_date || new Date(),
        expirationDate:
          userData?.DateOfExpiration || credentials?.expiration_date,
        documentStatus: credentials?.document_status || "pending",
        verificationDate: credentials?.verification_date || new Date(),
        firstName: userData?.FirstName || credentials?.firstname,
        lastName: userData?.LastName || credentials?.lastname,
        dateOfBirth: userData?.DateOfBirth || credentials?.date_of_birth,
        gender: userData?.Sex,
        addressLine1: userData?.Address?.streetAddress || credentials?.address1,
        addressLine2: credentials?.address2,
        city: userData?.Address?.city || credentials?.city,
        state: userData?.Address?.state || credentials?.state,
        zipcode: userData?.Address?.postalCode || credentials?.zip,
      },
      ...context?.scanData,
      status: context?.scanData?.api_status,
    };

    window.parent.postMessage(returnPayload, "*");
  };

  useEffect(() => {
    getWebhookPayload(sessionId!).then((res) => {
      setWebhookResult({ guid: res?.guid, puid: res?.puid });
      sessionStorage.setItem("user", JSON.stringify({ puid: res?.puid }));
    });
  }, [sessionId]);

  const user: any = JSON.parse(localStorage.getItem("user") ?? "{}");
  const userId = user?.user || user?._id;
  const viewCredentials = async () => {
    try {
      const credentialDetails: any = await getCredentialDetails(userId);
      if (credentialDetails?.id) {
        setCredentialData(credentialDetails);
      }
      return;
    } catch (e) {}
  };
  useEffect(() => {
    if (userId && !window.location.search) {
      viewCredentials();
    }
  }, [userId]);
  return (
    <Layout>
      <div className="h-full px-10 py-8 flex justify-between items-center flex-col max-md:p-[20px]">
        <div className="w-full">
          <div className="flex justify-between relative">
            <div />
            <CloseButton
              onClick={() => {
                context?.setPageConfiguration(pageConfig);
                window.location.href = "/";
              }}
            />
          </div>
          <div className="flex justify-center items-center mb-[-6rem]">
            <Lottie
              loop={false}
              autoplay={true}
              animationData={successJson}
              style={{
                height: isMobile ? 350 : 400,
                width: isMobile ? 380 : 400,
              }}
            />
          </div>
          {pageConfig?.type === "ageSuccess" ? (
            <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
              {pageConfig?.message} {context.age}
            </Label>
          ) : (
            <>
              <Label className="text-[20px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
                Webhook Response (Demo only):{" "}
              </Label>
              <div className="max-h-[20vh] overflow-x-scroll">
                {JSON.stringify(webhookResult, null, 2)}
              </div>
              <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
                {pageConfig?.message ||
                  context?.successMessage ||
                  Props.heading ||
                  "Success! Your account is created"}
              </Label>
              <br />
              {/* {projectName !== "register-i9" && (
                <Label className="text-[14px] font-[400] text-secondaryText">
                  uuid: {uuid?.slice(1, -1)}
                </Label>
              )} */}
            </>
          )}
          {pageConfig?.type === "completed" && (
            <p className="text-[14px] font-[400]">
              A text message was sent to your phone to confirm your Private ID
              account. No personal details, images or biometric data are
              retained. This account will expire after two years of inactivity.
            </p>
          )}
        </div>
        <div
          className={`text-left  absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
        >
          <div>
            {context?.configuration?.redirectUrl && (
              <a
                href={context?.configuration?.redirectUrl}
                target="_blank"
                className="block mt-[-30px] w-[200px] text-primary m-auto text-center cursor-pointer underline"
              >
                Go to Success URL
              </a>
            )}
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
              onClick={() => buttonHandler()}
            >
              {pageConfig?.buttonText || "Continue"}
            </Button>
            {/* {pageConfig?.secondaryButton && (
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() => {
                  onPostMessage();
                  context?.configuration?.isKantara
                    ? onSecondaryButton()
                    : pageConfig
                    ? backToHome()
                    : navigateWithQueryParams("/login-options", {
                        token: undefined,
                      });
                }}
              >
                {pageConfig?.secondaryButton || "Return to options"}
              </Button>
            )} */}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Success;