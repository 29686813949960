import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import useFaceLoginWithLivenessCheck from "hooks/useFaceLoginWithLiveness";
import { getUser, verifyOtp } from "services/api";
import qrImage from "assets/qrCode.svg";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  ERROR,
  SECURITY_CHALLENGE,
  SUCCESS,
} from "constant";
import { extractRoute, getDeviceHash, getStatusFromUser, getUrlParameter, isMobile } from "utils";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { useNavigation } from "utils/onNavigate";
import {
  generateAuthenticationOptions,
  verifyAuthentication,
} from "services/passkey";
import { startAuthentication } from "@simplewebauthn/browser";
import { useToast } from "components/ui/use-toast";
import { useSearchParams } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import QRCode from "react-qr-code";
import PinInput from "react-pin-input";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";

type Props = {
  heading?: string;
  continuousAuth?: boolean;
  onComplete?: (user: any) => void;
  cameraReady?: () => void;
};

const TAB_LIST = [
  {
    title: "Enter Pin",
  },
  {
    title: "Camera",
  },
  {
    title: "QR Code",
  },
];
function FaceLoginOtp(Props: Props | any) {
  const context: any = useContext(UserContext);
  const [tab, setTab] = useState("0");
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [completed, setCompleted] = useState(false);
  const onStatus = (e: number) => {};
  const token = getUrlParameter("token", "") ||
      "";
  const [otp, setOtp] = useState("");
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };
  const { toast } = useToast();
  const [searchParams] = useSearchParams();
  const tokenParams: any = searchParams.get("token");
  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginResponseStatus,
    faceLoginInputImageData,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onStatus,
    50,
    false,
    onPredictFail
  );
  const onSuccess = () => {
    context.setUser({
      ...context.user,
      uuid: faceLoginData?.puid,
      guid: faceLoginData?.guid,
    });
    handelLoginResponse(faceLoginData);
  };

  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(context?.configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  const onFailPasskey = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    toast({
      variant: "destructive",
      description: "There was some issue authenticating with passkey.",
    });
    loadPageConfiguration();
    navigateWithQueryParams("/", { token: undefined });
  };

  const onTabChange = (value: any) => {
    setTab(value);
  };

  const onAuthenticatePasskey = async () => {
    try {
      const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
      const hash = await getDeviceHash().then((hash) => hash);
      const response = await generateAuthenticationOptions(token, hash);
      if (response?.challenge) {
        let asseResp;
        try {
          asseResp = await startAuthentication(response);
        } catch (error) {
          console.log({ error });
        }
        const verificationJSON = await verifyAuthentication({ asseResp, token });
        const alreadyEnroll =
          verificationJSON?.verified &&
          context?.user?.alreadyEnrolled &&
          pageConfig?.type === "enrollPasskey";
        if (verificationJSON?.verified) {
          context.setCheckoutCompleted(true);
          context.setSuccessMessage("Success! Your account is created");
          if (alreadyEnroll) {
            context.setLoginOption("checkoutVc");
            context?.setSuccessMessage("Authentication successful!");
            navigateToNextPage("", "/vc-proof", "/login-dl");
          } else if (context?.configuration?.isKantara) {
            navigateToNextPage();
          } else if (pageConfig) {
            context?.setSuccessMessage("Authentication successful!");
            navigateToNextPage("", "/vc-proof", "/generate-passkey");
          } else {
            navigateToNextPage("", "/vc-proof", "/generate-passkey");
          }
        } else {
          onFailPasskey();
        }
      } else {
        onFailPasskey();
      }
    } catch (e) {
      onFailPasskey();
    }
  };

  const handelLoginResponse = async (result: any) => {
    if (result?.status === 0 || result?.success) {
      localStorage.setItem(
        "uuid",
        JSON.stringify(result?.puid || result?.user?.puid || {})
      );
      const payload = {
        guid: result?.guid || result?.user?.guid,
        uuid: result?.puid || result?.user?.puid,
        checkVC: false,
      };
      // This is for kantara flow
      if (
        context?.configuration?.isKantara &&
        window.location.pathname === "/predict-scan"
      ) {
        context.setUser({
          ...context.user,
          enrollImageData: faceLoginInputImageData,
        });
        navigateToNextPage();
        return;
      }
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR || data?.data?.statusCode === 404) {
        context.setFailedMessage(AUTHENTICATION_FAILED);
        navigateWithQueryParams("/failed");
        context.setUser({
          ...context.user,
          data,
        });
        localStorage.setItem("user", JSON.stringify(data.user || {}));
        return;
      }
      const user =
        data?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
      if (user._id) {
        const userStatus = getStatusFromUser(data.status);
        if (userStatus === SUCCESS) {
          context.setUser({
            ...context.user,
            ...user,
          });
          localStorage.setItem("user", JSON.stringify(user || {}));
          switch (context.loginOption) {
            case "passkey":
              return pageConfig
                ? navigateToNextPage("", "/login-passkey", "/face-login")
                : navigateWithQueryParams("/login-passkey");
            case "loginPin":
              context?.setSuccessMessage("Authentication successful!");
              if (pageConfig) {
                navigateToNextPage("", "/verify-pin", "/face-login");
              } else {
                navigateWithQueryParams("/verify-pin");
              }
              break;
            case "loginDl":
              return pageConfig
                ? navigateToNextPage(
                    "",
                    "/drivers-licence-back-intro",
                    "/face-login"
                  )
                : navigateWithQueryParams("/drivers-licence-back-intro");
            case "loginVc":
              return pageConfig
                ? navigateToNextPage("", "/login-passkey", "/face-login")
                : navigateWithQueryParams("/login-passkey");
            case "checkoutVc":
              return onAuthenticatePasskey();
            case "accountRecovery":
              return pageConfig
                ? navigateToNextPage(
                    "",
                    "/drivers-licence-back-intro",
                    "/face-login"
                  )
                : navigateWithQueryParams("/drivers-licence-back-intro");
            case SECURITY_CHALLENGE:
              return pageConfig
                ? navigateToNextPage("", "/challenge", "/face-login")
                : navigateWithQueryParams("/challenge");
            default:
              localStorage.setItem("user", JSON.stringify(user || {}));
              context?.setSuccessMessage("Authentication successful!");
              if (pageConfig) {
                if (Props.continuousAuth) {
                  Props?.onComplete(user);
                } else {
                  navigateToNextPage("", "/success", "/face-login");
                }
              } else {
                if (Props.continuousAuth) {
                  Props?.onComplete(user);
                } else {
                  navigateWithQueryParams("/success");
                }
              }
              break;
          }
        } else {
          context.setFailedMessage(ACCOUNT_NOT_APPROVED);
          navigateWithQueryParams("/failure");
        }
      }
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      navigateWithQueryParams("/failed");
    }
  };

  useEffect(() => {
    if (faceLoginResponseStatus === 0) {
      setCompleted(true);
    }
  }, [faceLoginWithLivenessMessage]);

  const onCameraReady = () => {
    if (Props.continuousAuth) {
      Props?.cameraReady();
    } else {
      faceLoginWithLiveness();
    }
  };

  const urlForRedirect =
    window.location.origin + "/mobile-login" + "?token=" + tokenParams;

  const onVerifyOtp = async () => {
    const payload = {
      pin: otp,
    };
    const result: { pin: string } | any = await verifyOtp(tokenParams, payload);

    if (result?.success) {
      toast({
        variant: "success",
        description: "Otp verified",
      });
      handelLoginResponse(result);
    } else if (result?.status === 406) {
      toast({
        variant: "destructive",
        description: result?.data?.message,
      });
    }
  };
  const _renderTabs = () => {
    return (
      <Tabs
        defaultValue={"0"}
        className="w-full"
        value={tab}
        onValueChange={onTabChange}
      >
        <TabsList
          style={{ background: "none" }}
          className="grid w-full grid-cols-3"
        >
          {TAB_LIST?.map((item: any, index: any) => (
            <TabsTrigger value={index?.toString()} className="">
              {item?.title}
            </TabsTrigger>
          ))}
        </TabsList>
        <TabsContent value={"0"} className="mt-5">
          <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px]  max-md:h-[340px] max-md:overflow-auto h-[500px] flex justify-center">
            <div className="relative">
              <Label className="text-[28px] font-[500] text-primaryText mb-[20px] block mt-[20px]">
                Enter your PIN to continue
              </Label>
              <PinInput
                length={4}
                initialValue=""
                secret
                focus
                secretDelay={100}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                ariaLabel={`pin`}
                inputStyle={{
                  borderColor: "gray",
                  width: isMobile ? "30px" : "50px",
                  height: isMobile ? "30px" : "50px",
                  fontSize: isMobile ? "20px" : "24px",
                  marginLeft: 10,
                  transition: "all .2s",
                }}
                inputFocusStyle={{
                  borderColor: "#5283EC",
                  transform: "scale(1.1)",
                }}
                onComplete={(value) => {
                  setOtp(value);
                }}
                autoSelect={true}
                regexCriteria={/^[0-9]*$/}
              />
              <Button
                className="text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary w-[70%]"
                disabled={!otp}
                onClick={() => onVerifyOtp()}
              >
                {pageConfig?.buttonText || "Continue"}
              </Button>
              <div>
                <Button
                  className="w-full text-white bg-transparent text-primary rounded-[24px] p-[0px] mt-[20px] h-[unset] hover:opacity-90 hover:underline"
                  onClick={() => onTabChange("1")}
                >
                  Continue with face
                </Button>
                <Button
                  className="w-full text-white bg-transparent text-primary rounded-[24px] p-[0px] mt-[10px]  h-[unset] hover:opacity-90 hover:underline"
                  onClick={() => onTabChange("2")}
                >
                  Continue with Qr code
                </Button>
              </div>
            </div>
          </Card>
        </TabsContent>
        <TabsContent value={"1"} className="mt-[50px]">
          <div className="mt-[20px]">
            <CameraComponent
              faceLoginCamera={true}
              onCameraReady={onCameraReady}
              progress={faceLoginResponseStatus === 0 ? 99 : 0}
              message={faceLoginWithLivenessMessage}
              onSuccess={onSuccess}
              attempt={typeof faceLoginResponseStatus === "number" ? 1 : 0}
              scanCompleted={completed}
              onCameraSwitch={() => faceLoginWithLiveness()}
            />
          </div>
        </TabsContent>
        <TabsContent value={"2"} className="mt-5">
          <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px] h-[500px]  max-md:h-[340px] max-md:overflow-auto">
            <CardHeader className="p-0 flex flex-row items-center">
              <div className="flex-1">
                <CardTitle className="text-left text-[18px]">
                  Scan QR code.
                </CardTitle>
                <CardDescription className="text-left">
                  <div className="mt-2 w-[100%] m-auto max-md:w-[100%] text-[14px]">
                    Please scan the QR Code with your mobile phone device to
                    continue your verification.
                  </div>
                </CardDescription>
              </div>
              <img
                src={qrImage}
                alt="qr_image"
                className="w-[138px] h-[118px] !mt-0"
              />
            </CardHeader>
            <CardContent className="space-y-2 mt-7">
              <div className="flex justify-between items-center">
                <div className="bg-[#fff] shadow-2xl p-4 rounded-[20px]">
                  <QRCode size={120} value={urlForRedirect} />
                </div>
                <div className="border border-[#ccc] w-[2px] h-[120px] mx-[20px] block" />
                <div className="w-full">
                  <CardTitle className="text-left text-[18px] mt-[-20px]">
                    Already have a pin?
                  </CardTitle>
                  <CardTitle className="text-left text-[14px] font-[400] mt-[2px] text-[#4c5667]">
                    Click here to verify your pin.
                  </CardTitle>
                  <Button
                    className="text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary w-[70%]"
                    onClick={() => setTab("0")}
                  >
                    {"Verify pin"}
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
    );
  };

  return (
    <>
      {Props.continuousAuth ? (
        <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[0px]">
          <div className="mt-[0px] rounded-[20px] flex flex-col items-center justify-center">
            {_renderTabs()}
          </div>
        </div>
      ) : (
        <Layout removeHeight={!isCameraGranted}>
          <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
            <div className="flex justify-between relative max-md:p-0">
              <BackButton />
              <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
                <img src={lock} alt="lock" className="mr-[5px]" />
                {pageConfig?.title || "Biometrics never leave this device"}
              </div>
            </div>
            <div className="mt-[0px] rounded-[20px] flex flex-col items-center justify-center">
              {_renderTabs()}
            </div>
          </div>
        </Layout>
      )}
    </>
  );
}

export default FaceLoginOtp;
