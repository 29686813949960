"use client";

import { Button } from "components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "components/ui/command";
import { cn } from "../../../lib/utils";
import { Countries } from "constant/country-flag";

export function ComboboxDemo({ setCountry, country }: any) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(""); 

  const filteredCountries = Countries.filter((item: any) =>
    item.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[100%] justify-between text-[14px] font-[400]"
        >
          <div className="flex">
            <img
              src={
                Countries.find(
                  (item: any) => item.code?.toLocaleLowerCase() === country
                )?.image
              }
              alt={"flag"}
              className="h-[20px] width-[20px]"
            />
            <p className="ml-[15px]">
              {country
                ? Countries.find(
                    (item: any) => item.code?.toLocaleLowerCase() === country
                  )?.name
                : "Select your country..."}
            </p>
          </div>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[calc(65vw-36.5rem)] p-0 max-md:w-full">
        <Command>
          <CommandInput
            placeholder="Search your country..."
            className="h-9"
            value={search}
            onValueChange={setSearch}
            onInput={(e) => {
              e.currentTarget.value = e.currentTarget.value.replace(
                /[0-9]/g,
                ""
              );
            }}
          />
          <CommandEmpty>No country found.</CommandEmpty>
          <CommandGroup className="h-[300px] overflow-auto">
            {filteredCountries.map(
              (
                item: {
                  name: string;
                  code: string;
                  image: string;
                },
                index: number
              ) => (
                <CommandItem
                  value={item?.name?.toLowerCase()}
                  key={`${item?.code}-${index}`}
                  onSelect={() => {
                    setCountry(item?.code?.toLowerCase());
                    setOpen(false);
                    setSearch("");
                  }}
                >
                  <div className="flex">
                    <img
                      src={item?.image}
                      alt={"flag"}
                      className="h-[20px] width-[20px]"
                    />
                    <p className="ml-[15px]">{item?.name}</p>
                  </div>
                  <CheckIcon
                    className={cn(
                      "ml-auto h-4 w-4",
                      country === item?.code?.toLowerCase()
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                </CommandItem>
              )
            )}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
