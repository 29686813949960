import { useContext, useState } from "react";
import userLogo from "assets/login/user-icon.svg";
import emailIcon from "assets/login/email.svg";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { UserContext } from "context/userContext";
import { createUserWithSession } from "@privateid/cryptonets-web-sdk";
import { useToast } from "components/ui/use-toast";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import { Landmark } from "lucide-react";
import CloseButton from "common/components/closeButton";
import PhoneInput from "../components/phoneInput";
import { useNavigation } from "utils/onNavigate";
import { useForm } from "react-hook-form";
import { formSchema } from "schema/form";
import { yupResolver } from "@hookform/resolvers/yup";
import { extractRoute } from "utils";

const inputs = [
  {
    id: 6,
    name: "phone",
    placeholder: "Enter your phone number *",
    type: "phone",
    enable: true,
    icon: null,
  },
  {
    id: 7,
    name: "email",
    placeholder: "Enter your email *",
    type: "email",
    enable: true,
    icon: emailIcon,
  },
  {
    id: 8,
    name: "ssn4",
    placeholder: "Enter last 4 digits of SSN",
    type: "tel",
    enable: true,
    icon: null,
  },
];

const PersonalInfo = () => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { setUser, tokenParams, user } = useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { toast } = useToast();
  const [loader, setLoader] = useState(false);
  const [phone, setPhone] = useState<string>("");
  const [ssn, setSsn] = useState("");
  const phoneNumber = phone?.replaceAll("-", "");
  const [errors, setErrors] = useState({
    phoneError: "",
    emailError: "",
  });
  const { navigateToNextPage } = useNavigation();

  const onBlur = (type: string) => {
    switch (type) {
      case "phone":
        setErrors({
          ...errors,
          phoneError: "",
        });
        return;
      default:
        break;
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors: formError },
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const ssnPattern = "xxx-xx-";
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numInput = e.target.value.replace(/\D/g, "");
    if (numInput.length <= 4) {
      setSsn(numInput);
    }
  };
  const Errors: any = formError;
  const renderInput = (form: any) => {
    switch (form?.type) {
      case "phone":
        return (
          <div className="relative">
            <PhoneInput
              setValue={setPhone}
              errorMessage={errors?.phoneError}
              handleFocus={() => onBlur("phone")}
              placeholder={form?.placeholder}
              ariaLabel={form?.placeholder}
              phone={phone}
            />
          </div>
        );
      default:
        return (
          <div className="relative">
            {form?.name === "ssn4" ? (
              <Input
                placeholder={form?.placeholder}
                className={`mt-5 ${
                  form?.icon || form?.name === "ssn4" ? "ps-9 " : ""
                } h-[56px] rounded-[4px] placeholder:text-placeholder placeholder:font-normal`}
                {...register(form?.name)}
                error={Errors?.[form?.name]?.message}
                name={form?.name}
                value={ssn ? ssnPattern + ssn : ssn}
                onChange={handleChange}
                aria-label={form?.placeholder}
              />
            ) : (
              <Input
                placeholder={form?.placeholder}
                className={`mt-5 ${
                  form?.icon || form?.name === "ssn4" ? "ps-9 " : ""
                } h-[56px] rounded-[4px] placeholder:text-placeholder placeholder:font-normal`}
                {...register(form?.name)}
                error={Errors?.[form?.name]?.message}
                name={form?.name}
                type={form?.type}
                aria-label={form?.placeholder}
              />
            )}
            {form?.name === "ssn4" ? (
              <Landmark className="absolute top-4 left-2 font-[400] text-primary " />
            ) : form?.icon ? (
              <img
                src={form?.icon || emailIcon}
                alt="email-icon"
                className="absolute top-4 left-2 font-[400] text-primaryText"
              />
            ) : (
              ""
            )}
          </div>
        );
    }
  };
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption('');
    context?.setPageConfiguration(pageConfiguration);
  };
  // console.log(formError, "errors");
  const onSubmitHandler = async (data: any) => {
    if (phoneNumber?.length < 10) {
      setErrors({ ...errors, phoneError: "Please enter a valid phone number" });
      return;
    }
    const payload = {
      sessionToken: tokenParams,
      ...data,
      phone: phoneNumber,
      ssn: data?.ssn4?.split("-")[2],
      email: data?.email || undefined
    };
    setUser({
      ...user,
      phone: phoneNumber,
      userDetails: {
        ...data,
        ssn: data?.ssn4?.split("-")[2],
        phone: phoneNumber,
        email: data?.email || undefined
      },
    });
    setLoader(true);
    const userResult = await createUserWithSession(payload);
    if (userResult?.error === "Not Acceptable") {
      toast({
        variant: "destructive",
        description: userResult?.message,
      });
      reset();
      setTimeout(() => {
        loadPageConfiguration();
        navigateWithQueryParams("/", { token: undefined });
      }, 2000);
    }
    setLoader(false);
    if (userResult?.success) {
      reset();
      if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/face-scan-intro");
      }
    }
  };

  const renderButton = () => {
    return (
      <div
        className={`text-left h-[100px] absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
      >
        <div>
          <Button
            className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
            disabled={loader}
            type="submit"
          >
            {pageConfig?.buttonText || "Continue"}
          </Button>
        </div>
      </div>
    );
  };
  return (
    <Layout>
      <form onSubmit={handleSubmit(onSubmitHandler)} style={{ height: "100%" }}>
        <div className="px-10 py-8 max-md:p-[20px]">
          <div className="flex justify-between relative">
            <BackButton />
            <div className="w-full h-[48px]">
              <img src={userLogo} alt="consentLogo" className="m-auto" />
            </div>
            <CloseButton />
          </div>
          <div className="mt-2">
            <Label className="text-[28px] font-[500] text-primaryText">
              {pageConfig ? pageConfig?.title : "Personal details"}
            </Label>
          </div>
          <div className="text-center overflow-auto h-[420px] mt-0 p-2">
            <div className="ps-8 pe-8 mb-[20px]">
              <Label className="text-[14px] font-[400] text-secondaryText">
                {pageConfig
                  ? pageConfig?.subheading
                  : "Please enter the required information"}
              </Label>
            </div>
            <div className="">
              {(pageConfig?.inputs || inputs)?.map((item: any) => {
                if (!item?.enable) return;
                return <div key={item?.type}>{renderInput(item)}</div>;
              })}
            </div>
            {(pageConfig?.inputs || inputs)?.length <= 3 && renderButton()}
          </div>
          {(pageConfig?.inputs || inputs)?.length > 3 && renderButton()}
        </div>
      </form>
    </Layout>
  );
};

export default PersonalInfo;
