import { useContext, useEffect } from "react";
import { Button } from "../../../components/ui/button";
import { Label } from "../../../components/ui/label";
import NotApprovedImage from "../../../assets/not-approved.svg";
import Layout from "../../../common/layout";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { useNavigation } from "utils/onNavigate";
import { UserContext } from "context/userContext";
import { extractRoute } from "utils";
import userAvatar from "assets/userAvatar.png";
import {useNavigateWithQueryParams} from "../../../utils/navigateWithQueryParams";

type Props = {
  heading?: string;
  showFooter?: boolean;
};

const NotApproved = (Props: Props) => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateToNextPage } = useNavigation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption('');
    context?.setPageConfiguration(pageConfiguration);
  };
  useEffect(() => {
    context.setLoginOption("");
  }, []);
  const isPredictFail = pageConfig?.type === "predictFail";
  return (
    <Layout>
      <div className="h-full px-10 py-8 flex justify-between items-center flex-col max-md:p-[20px]">
        <div className="w-full">
          <div className="flex justify-between relative">
            {/* <BackButton /> */}
            <CloseButton />
          </div>
          {isPredictFail && (
            <>
              <Label className="text-[28px] mt-[30px] font-[500] text-primaryText w-[100%] ">
                {pageConfig?.message ||
                  Props.heading ||
                  "Your account is not approved."}
              </Label>
              <p className="text-[18px] font-[200] text-primaryText w-[100%] mt-1">
                There is a problem with your account.
              </p>
            </>
          )}
          <div className="flex justify-center items-center mt-[0px]">
            <img
              src={isPredictFail ? userAvatar : NotApprovedImage}
              alt=""
              className={isPredictFail ? "w-[300px] mt-[30px]" : "w-[80px]"}
            />
          </div>
          {!isPredictFail && (
            <>
              <Label className="text-[28px] font-[500] text-primaryText w-[100%] mt-10">
                {pageConfig?.message ||
                  Props.heading ||
                  "Your account is not approved."}
              </Label>
              <p className="text-[18px] font-[200] text-primaryText w-[100%] mt-1">
                There is a problem with your account.
              </p>
            </>
          )}
        </div>
        <div
          className={`text-left h-[154px] absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
        >
          <div>
            {/* <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
              onClick={() => {
                if (pageConfig) {
                  loadPageConfiguration();
                  window.location.href = '/'
                } else {
                  navigateWithQueryParams("/login-options", { token: undefined });
                }
              }}
            >
              {pageConfig?.buttonText || "Try again"}
            </Button> */}
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
              onClick={() => {
                if (pageConfig) {
                  loadPageConfiguration();
                  window.location.href = '/'
                } else {
                  window.location.href = '/'
                }
              }}
            >
              {pageConfig?.secondaryButton || "Register again"}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotApproved;
