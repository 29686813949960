import { UserContext } from "context/userContext";
import * as React from "react";
import { convertLinkToImageData, getStatusFromUser, getUrlParameter, isMobile, LoadPageConfiguration } from "utils";
import useWasm from "hooks/useWasm";
import { UAParser } from "ua-parser-js";
import Camera from "V2/camera";
import { useSearchParams } from "react-router-dom";
import { getUser, getUserPortrait, verifyTokenApi } from "services/api";
import { AUTH, FLOW, PUBLIC_KEY, REQUIRES_INPUT, SUCCESS } from "constant";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useNavigation } from "utils/onNavigate";

interface componentNameProps {
  children: React.ReactNode;
  backgroundColor?: string;
  logoBackgroundColor?: string;
  camera?: boolean;
  className?: string;
  innerContainer?: string;
  allowCamera?: boolean;
}
let pageLoadded = false;
const Layout = (props: componentNameProps) => {
  const { className, innerContainer, allowCamera = false } = props;
  const [searchParams] = useSearchParams();
  const { browser } = UAParser(navigator.userAgent);
  const isMobileSafari = browser?.name === "Mobile Safari";
  const url = process.env.REACT_APP_WASM_API_URL || "";
  const { ready: wasmReady, wasmStatus } = useWasm(
    getUrlParameter("token", "") || "",
    url,
    PUBLIC_KEY
  );
  const token = searchParams.get("token");
  const context = React.useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  React.useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")[0];
    bodyElement.style.backgroundColor = "#272b34";
  
    return () => {
      bodyElement.style.backgroundColor = ""; // Reset background color on unmount
    };
  }, []);
  const {
    configuration,
    setTokenParams,
    setUser,
    user,
    setSuccessMessage
  }: { configuration: any } | any = context;
  const logoImage = configuration?.logo;
  const backgroundColor = props.backgroundColor || "";
  const logoBackgroundColor = props.logoBackgroundColor || "#515257";
  const renderCamera = () => {
    if (!allowCamera) return;
    return (
      <div className="absolute left-0 top-0 h-full w-full rounded-[24px] overflow-hidden blur-lg opacity-20 z-0">
        <Camera onCameraReady={() => {}} />
      </div>
    );
  };

  const verifyTokenAPI = async (token: any) => {
    setTokenParams(token?.replace("?", ""));
    await verifyTokenApi(token).then(handleTokenVerification);
  };

  const handleTokenVerification = async (res: any) => {
    if (res?.user && res?.type !== AUTH) {
      await handleCustomerVerification(res);
    }
  };

  const handleCustomerVerification = async (res: any) => {
    setUser({ ...user, userId: res.user });
    const isCompleted = (element: number) =>
      res.flowsCompleted.includes(element);
    try {
      console.log(!res.flowsCompleted.length,'!res.flowsCompleted.length');
      
      if (!res.flowsCompleted.length) {
        LoadPageConfiguration(context, window.location.pathname)
        navigateWithQueryParams("/", { token: undefined });
      } else if (
        res.flowsCompleted.length === 1 &&
        isCompleted(FLOW.CREATE_USER)
      ) {
        if (!configuration?.isKantara) {
          navigateToNextPage(
            `token=${res?.token}`,
            `/v2/scan-face`,
            "/switch-device"
          );
        }
      } else if (
        res.flowsCompleted.length === 2 &&
        [FLOW.CREATE_USER, FLOW.UPLOAD_SELFIE].every(isCompleted)
      ) {
        const userPortrait: any = await getUserPortrait(res.token);
        if (userPortrait?.data) {
          try {
            const enrollImageData = await convertLinkToImageData(
              userPortrait.data
            );
            setUser({ ...user, enrollImageData });
            navigateToNextPage(
              `token=${res?.token}`,
              `/v2/driver-license`,
              "/switch-device"
            );
          } catch (error) {
            console.error(error);
          }
        } else {
          navigateToNextPage(
            `token=${res?.token}`,
            `/v2/failure-screen`,
            "/switch-device"
          );
        }
      } else if (
        res.flowsCompleted.length === 3 &&
        [FLOW.CREATE_USER, FLOW.UPLOAD_SELFIE, FLOW.UPLOAD_DOC_FRONT].every(
          isCompleted
        )
      ) {
        navigateToNextPage(
          `token=${res?.token}`,
          `/v2/back-driver-license`,
          "/switch-device"
        );
      } else {
        const data: any = await getUser({ id: res.user });
        const { userApproved, requestScanID, requestResAddress, ...rest } =
          data.status || {};

        setUser({
          ...user,
          userStatus: {
            userApproved,
            requestScanID,
            requestResAddress: !requestScanID && requestResAddress,
            ...rest,
          },
        });
        handleUserStatus(userApproved, rest, res);
      }
    } catch (e) {
      LoadPageConfiguration(context, '/')
      navigateWithQueryParams("/", { token: undefined });
    }
  };

  const handleUserStatus = (userApproved: any, rest: any, res: any) => {
    const status = getStatusFromUser({ userApproved, ...rest });

    if (status === SUCCESS) {
      setSuccessMessage("Success! Your account is created");
      navigateToNextPage(`token=${res?.token}`, `/v2/success`, "/switch-device");
    } else if (status === REQUIRES_INPUT) {
      navigateWithQueryParams(`/address`, { token: res?.token });
    } else {
      navigateWithQueryParams(`/v2/failure-screen`, { token: res?.token });
    }
  };

  React.useEffect(() => {
    if (!token) return;
    if (!pageLoadded) {
      verifyTokenAPI(token);
      pageLoadded = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, pageLoadded]);
  return (
    <div
      className={`bg-[#272b34]  h-[100vh] max-md:p-[0px] p-[10px] overflow-auto ${isMobileSafari ? 'max-md:h-[calc(100vh_-_5rem)]' : 'max-md:h-[calc(100vh_-_3rem)]'}  max-md:overflow-hidden ${className}`}
      style={{ backgroundColor: isMobile ? `${logoBackgroundColor}` : "" }}
    >
      <div className="max-w-[600px] m-auto max-md:h-[100%]">
        <img
          src={logoImage}
          alt=""
          data-src={logoImage}
          style={{ filter: "brightness(0) invert(1)" }}
          className={`lazyload cursor-pointer m-auto w-[100px]  mt-[20px] mb-[20px] z-[99999] relative`}
          onClick={() => window.open('/', '_self')}
        />
        <div
          className={`w-[100%] relative  h-[705px] min-md:border border-[#FFFFFF1A] max-md:rounded-[0px] rounded-[24px] ${
            props?.camera ? "overflow-hidden" : "p-[10px]"
          } max-md:h-[calc(100vh_-_10rem)] ${
            window.innerWidth < 767
              ? isMobileSafari
                ? "[@media_((min-height:700px)_and_(max-height:1000px))]:h-[calc(100vh_-_10rem)] overflow-hidden"
                : "[@media_((min-height:700px)_and_(max-height:730px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:731px)_and_(max-height:750px))]:h-[calc(100vh_-_10rem)] [@media_((min-height:751px)_and_(max-height:1000px))]:h-[calc(100vh_-_10rem)]"
              : ""
          } ${innerContainer}`}
          style={{
            backgroundSize: "cover",
            backgroundColor,
            backgroundPosition: "center",
          }}
        >
          {props?.children}
          {renderCamera()}
        </div>
      </div>
    </div>
  );
};

export default Layout;
