/* Core */
import { useState, useRef } from "react";
import { multiframeTwoStepFaceLogin } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";
/* Services */
// import sessionService from "../services/sessionService";
/* Context Hooks */
// import useContextService from "../services/useContextService";

const useMultiframePredictV2 = ({
  onSuccess,
  scopes,
  antiSpoofingThreshold,
  skipAntispoof,
  frameCount,
}: any) => {
  const [faceLoginMessage, setFaceLoginMessage] = useState("");
  const [progress, setProgress] = useState(0);
  const [multiframePredictGUID, setPredictGUID] = useState<string>("");
  const [multiframePredictPUID, setPredictPUID] = useState<string>("");
  const isFaceProcession = useRef<any>(null);
  const [multiframePredictValidationStatus, setPredictValidationStatus] =
  useState<number | null>();
  // const { setPLogs } = useContextService();

  let predictCount = 0;

  const callback = async (result: any) => {
    console.log("predict - server response - success:", JSON.stringify(result));

    if (result?.processing) {
      // console.log("useFaceLogin -> faceCaptured -> Started Processiing at:: ", sessionService.getTime());
      // setPLogs({ predictProcessingStart: sessionService.getTime() });
      isFaceProcession.current = true;
      predictCount = 0;
      calculateProgress(predictCount);
      setFaceLoginMessage("");
      setPredictValidationStatus(result.face_validation_status);
    } else if (result?.is_predicted && result?.face_validation_status === -100) {
        isFaceProcession.current = false;
    }
    //Success case
    else if (result?.guid && result?.puid) {
      // SUCCESSFUL FACE LOGIN
      // setPLogs({ predictProcessingStopped: sessionService.getTime() });
      setPredictGUID(result?.guid);
      setPredictPUID(result?.puid);
      onSuccess(result);
      setFaceLoginMessage("");
      setPredictValidationStatus(result.face_validation_status);
      // console.log("useFaceLogin -> faceCaptured -> Stop Processiing at:: ", sessionService.getTime());
    } else if (result?.noFaceFound) {
      predictCount = 0;
      calculateProgress(predictCount);
      // move camera
      if (!isFaceProcession.current) {
        multiframePredictUserOneFa();
        setFaceLoginMessage("");
      }
    } else if (result?.valid_frame === true) {
      predictCount++;
      calculateProgress(predictCount);
      setFaceLoginMessage("Please hold the position");
      multiframePredictUserOneFa();
    } else if (result?.antispoof_status === 1) {
      // spoof detected
      setFaceLoginMessage("Move your face");
      predictCount = 0;
      calculateProgress(predictCount);
      // move camera
      if (!isFaceProcession.current) {
        multiframePredictUserOneFa();
      }
    } else if (
      result?.face_validation_status !== 0 ||
      result?.antispoof_status !== 0
    ) {
      // feedback from face validation
      setFaceLoginMessage(getStatusMessage(result?.face_validation_status));
      predictCount = 0;
      calculateProgress(predictCount);
      // move camera
      if (!isFaceProcession.current) {
        multiframePredictUserOneFa();
      }
    } else if (result?.api_status === -1) {
      // DISPLAY SOMETHING LIKE USER IS NOT ENROLLED OR USER NOT REGISTERED
      predictCount = 0;
      calculateProgress(predictCount);
      multiframePredictUserOneFa();
      setFaceLoginMessage("User not enrolled");
    } else {
      setFaceLoginMessage(getStatusMessage(result?.face_validation_status));
      setPredictValidationStatus(result.face_validation_status);
      if (!isFaceProcession.current) {
        multiframePredictUserOneFa();
      }
    }
  };

  const multiframePredictUserOneFa = async () => {
    console.log(frameCount,'frameCount');

    const config = {
      send_original_images:
        scopes?.includes("selfie") ||
        scopes?.includes("liveness_score") ||
        scopes?.includes("face_compare_score")
          ? true
          : false,
      input_image_format: "rgba",
      // anti_spoofing_threshold: antiSpoofingThreshold,
      skip_antispoof: skipAntispoof,
      anti_spoofing_threshold: 0.8,
      threshold_user_too_far: 0.15,
      threshold_profile_predict: 0.65,
    }
    console.log(config,'config 110', frameCount);

    await multiframeTwoStepFaceLogin({
      callback,
      config: config,
      frameCount,
    });
  };

  const calculateProgress = (predictCount: any) => {
    setProgress((predictCount / (frameCount - 1)) * 100);
  };

  return {
    multiframePredictUserOneFa,
    multiframePredictMessage: faceLoginMessage,
    isFaceProcession: !!isFaceProcession.current,
    multiframePredictValidationStatus,
    multiframePredictGUID,
    multiframePredictPUID,
    progress,
  };
};

export default useMultiframePredictV2;
