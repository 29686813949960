import { useContext, useState } from "react";
import { predictDeleteUser } from "@privateid/ultra-web-sdk-alpha";
import { getStatusMessage } from "@privateid/ultra-web-sdk-alpha/dist/utils";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "context/userContext";

let userNotEnrolled = 0;
let USER_NOT_ENROLLED_THRESHOLD = 15;
const useFaceDeletionWithLivenessCheck = (
  setShowSuccess: any = () => {},
  retryTimes = 3,
  isInitialPredict = true,
  onPredictFail?: any
) => {
  const context: any = useContext(UserContext);
  const configuration = context?.configuration;
  const [searchParams] = useSearchParams();
  const [faceLoginWithLivenessMessage, setPredictMessage] = useState("");
  const [faceLoginSuccess, setFaceLoginSuccess] = useState(false);
  const [faceLoginInputImageData, setFaceLoginInputImageData] =
    useState<any>(null);
  const [faceLoginData, setPredictData] = useState<any>(null);
  const [faceDeleteResponseStatus, setFaceDeleteResponseStatus] =
    useState<number>();
  let tries = 0;
  const faceDeleteWithLiveness = async (skipAntispoof = false, mf_token='') => {
    const inputImage = await predictDeleteUser({
      callback: callback,
      config: {
        disable_predict_mf: false,
        mf_token,
        // mf_token_overrides for overriding frame counts default 5
      },
    });

    setFaceLoginInputImageData(inputImage);
  };

  const callback = async (result: any) => {
    console.log("THIS IS THE HOOK?!!!");
    console.log("faceLogin callback hook result:", result);

    if(result.mf_token && [0, 10].includes(result.face_validation_status)){
      faceDeleteWithLiveness(false, result.mf_token)
      setPredictMessage("Please hold still");
    }
    else{
      if (![0, 10].includes(result.face_validation_status) || ![0,-6].includes(result.antispoof_status)) {
        if (result.face_validation_status === 8) {
          setPredictMessage("Move face into frame");
        } else {
          setPredictMessage(getStatusMessage(result.face_validation_status));
        }
  
        faceDeleteWithLiveness();
        return;
      }
      handleWasmResponse(result);
    }    
   
  };

  const handleWasmResponse = (returnValue: any) => {
    if (returnValue?.face_validation_status !== -100) {
      setFaceDeleteResponseStatus(returnValue?.face_validation_status);
    }
    if (
      [0, 10, 11].includes(returnValue.face_validation_status) &&
      returnValue?.api_response?.success
    ) {
      handleValidImage(returnValue);
    } else if (returnValue.face_validation_status === -7) {
      handleUserNotEnrolled(returnValue);
    } else {
      handleInvalidImage(returnValue);
    }
  };

  const handleUserNotEnrolled = (returnValue: any) => {
    if (userNotEnrolled < USER_NOT_ENROLLED_THRESHOLD) {
      userNotEnrolled++;
      faceDeleteWithLiveness();
      setPredictMessage("Processing");
    } else {
      setShowSuccess(returnValue);
      setPredictData({
        ...returnValue,
        retryComplete: userNotEnrolled >= USER_NOT_ENROLLED_THRESHOLD,
      });
      userNotEnrolled = 0;
      onPredictFail();
    }
  };
  const handleValidImage = (returnValue: any) => {
    setPredictMessage("Valid Image");
    setPredictData({
      ...returnValue,
      retryComplete: !isInitialPredict && tries === retryTimes,
    });
    setFaceLoginSuccess(true);
    setShowSuccess(returnValue);
  };

  const handleInvalidImage = (returnValue: any) => {
    if (tries !== retryTimes) {
      if (isInitialPredict) {
        tries += 1;
      }
      faceDeleteWithLiveness();
    } else {
      setShowSuccess(returnValue);
      setPredictData({
        ...returnValue,
        retryComplete: !isInitialPredict && tries === retryTimes,
      });
      tries = 0;
    }
  };
  const resetFaceLogin = (callFunc = true) => {
    setPredictData(undefined);
    setFaceLoginInputImageData(null);
    setPredictMessage("");
    if (callFunc) {
      faceDeleteWithLiveness();
    }
  };

  return {
    faceDeleteWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginInputImageData,
    faceLoginData,
    resetFaceLogin,
    faceDeleteResponseStatus,
    faceLoginSuccess,
  };
};

export default useFaceDeletionWithLivenessCheck;