import QRCode from "react-qr-code";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import ComputerIcon from "assets/comp.svg";
import check from "assets/check.svg";
import emailIcon from "assets/login/email.svg";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { Input } from "components/ui/input";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { getUser, sendMessage, verifyOtp, verifyTokenApi } from "services/api";
import { useInterval } from "utils/useInterval";
import clipIcon from "assets/clipIcon.svg";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import { UserContext } from "context/userContext";
import CloseButton from "common/components/closeButton";
import PhoneInput from "../components/phoneInput";
import qrImage from "assets/qrCode.svg";
import linkImage from "assets/linkImage.svg";
import smsImage from "assets/smsImage.svg";
import emailImage from "assets/emailImage.svg";
import { getStatusFromUser, isMobile } from "utils";
import PinInput from "react-pin-input";
import { useToast } from "components/ui/use-toast";
import {
  ACCOUNT_NOT_APPROVED,
  AUTHENTICATION_FAILED,
  ERROR,
  SUCCESS,
} from "constant";

type Props = {
  resendCode?: boolean;
  limitFailed?: boolean;
  sent?: boolean;
};

const tabList = [
  {
    title: "QR Code",
  },
  {
    title: "Link",
  },
  {
    title: "Verify Pin",
  },
];

const registerTabList = [
  {
    title: "QR Code",
  },
  {
    title: "Link",
  },
  {
    title: "Sms",
  },
  {
    title: "Email",
  },
];
function SwitchDevice(props: Props) {
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [lodaing, setLoading] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState<null | number>(10000);
  const [timer, setTimer] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [smsLimit, setSmsLimit] = useState(0);
  const [searchParams] = useSearchParams();
  const tokenParams: any = searchParams.get("token");
  const [otp, setOtp] = useState("");
  const [tab, setTab] = useState("0");
  const [copied, setCopied] = useState(false);

  const onTabChange = (value: any) => {
    setTab(value);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(urlForRedirect);
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  useEffect(() => {
    let interval: any;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      if (smsLimit !== 4) {
        setIsResendDisabled(false);
      }
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);
  const sendPhone = async () => {
    const payload: any = {
      type: "phone",
      phone: `+1${phone}`,
      subject: "Continue your verification",
      message: `to continue your verification process, Please delete this message if you did not request this verification.`,
      endpoint: window.location.origin.slice(1),
    };
    setLoading(true);
    if (smsLimit === 0) {
      setTimer(30);
    } else if (smsLimit === 1) {
      setTimer(60);
    } else if (smsLimit === 2) {
      setTimer(90);
    }
    setIsResendDisabled(true);
    setSmsLimit(smsLimit + 1);
    await sendMessage(payload);
    setLoading(false);
  };
  const sendEmail = async () => {
    const payload = {
      type: "email",
      email,
      subject: "Continue your verification",
      message: `to continue your verification process, Please delete this message if you did not request this verification.`,
      endpoint: window.location.origin.slice(1),
    };
    setLoading(true);
    try {
      await sendMessage(payload);
      setEmail(""); 
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      setLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const regex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return regex.test(email);
  };

  useInterval(() => {
    verifyTokenApi(tokenParams).then(async (res: any) => {
      if (["SUCCESS", "FAILURE"].includes(res.status)) {
        setRefreshInterval(null);
        if (res.status === "SUCCESS") {
          const payload = {
            id: res.user,
          };
          const data: any = await getUser(payload);
          localStorage.setItem("uuid", JSON.stringify(data.user.uuid || {}));
          successSessionRedirect(tokenParams, false, false);
        } else {
          failureSessionRedirect(tokenParams, false);
        }
      }
    });
  }, refreshInterval);

  const failureSessionRedirect = (
    session: { failureUrl: string | URL },
    redirectUrl = false
  ) => {
    if (redirectUrl && session.failureUrl) {
      setTimeout(() => {
        window.location.replace(session.failureUrl);
      }, 2000);
    } else {
      return navigateWithQueryParams("/failed");
    }
  };

  const successSessionRedirect = (
    session: { successUrl: string | URL },
    redirectUrl = false,
    passKey = true
  ) => {
    if (passKey) {
      return navigateWithQueryParams("/generate-passkey");
    } else if (redirectUrl && session.successUrl) {
      setTimeout(() => {
        window.location.replace(session.successUrl);
      }, 2000);
    } else {
      context?.setSuccessMessage("Success! Your account is created");
      return navigateWithQueryParams("/success");
    }
  };

  const from = searchParams.get("from");
  const isOtp = from === "/continuous-authentication-v2";
  const urlForRedirect =
    window.location.origin +
    (isOtp ? "/mobile-login" : "/user-consent") +
    "?token=" +
    tokenParams + '&from=switch-mobile';

  const List =
    pageConfig?.qrCode > 0
      ? pageConfig?.qrCode
      : isOtp
      ? tabList
      : registerTabList;
  const renderContent = (contentType: any, item: any) => {
    switch (contentType) {
      case "qrCode":
        return (
          <CardContent className="space-y-2 mt-4">
            <div className="flex justify-center items-center">
              <div className="bg-[#fff] shadow-2xl p-4 rounded-[20px]">
                <QRCode size={130} value={urlForRedirect} />
              </div>
            </div>
          </CardContent>
        );
      case "link":
        return (
          <CardContent className="space-y-2 ps-8 pe-8 max-md:p-[10px]">
            <div className="flex justify-center h-[178px]">
              <div className="relative w-full mt-5">
                <img
                  src={clipIcon}
                  alt="clipIcon"
                  className="absolute top-3.5 left-2"
                />
                <Input
                  placeholder=""
                  className="ps-[2rem] h-[50px] rounded-[8px] pe-[140px]"
                  defaultValue={urlForRedirect}
                />
                <Button
                  onClick={() => {
                    navigator.clipboard.writeText(urlForRedirect);
                  }}
                  className="absolute right-3 top-[6px] h-[38px] rounded-[24px] w-[113px] bg-primary hover:bg-primary text-white max-md:w-[90px]"
                >
                  {item?.buttonText}
                </Button>
              </div>
            </div>
          </CardContent>
        );
      case "sms":
        return (
          <CardContent className="space-y-2 ps-8 pe-8 pb-0 max-md:p-[10px]">
            <div
              className={`flex justify-center ${
                props?.resendCode ? "h-[145px]" : "h-[154px]"
              } `}
            >
              <div className="w-full mt-0">
                <PhoneInput setValue={setPhone} phone={phone} />
              </div>
            </div>
            {isResendDisabled && smsLimit !== 4 ? (
              <div className="!mt-[-45px] pb-5">
                <Label className="text-secondaryText text-[14px]">
                  Resend Code in <span className="text-primary">{timer}s</span>
                </Label>
              </div>
            ) : (
              ""
            )}
            {smsLimit === 4 ? (
              <div className="!mt-[-55px] pb-[6px] w-[70%] text-center m-auto">
                <Label className="text-[#FB6E4F] text-[14px] font-[400]">
                  Usage limit exceeded. Please try other methods to switch to
                  mobile device.{" "}
                </Label>
              </div>
            ) : (
              ""
            )}
            <div>
              <Button
                className={`w-full ${
                  isResendDisabled ? "text-[#9ba3b2]" : "text-white"
                } bg-primary rounded-[24px] mt-0 hover:opacity-90 hover:bg-primary`}
                disabled={phone?.length < 10 || lodaing || isResendDisabled}
                onClick={sendPhone}
              >
                {isResendDisabled ? (
                  <img src={check} className="mr-[3px]" alt="" />
                ) : (
                  ""
                )}
                {isResendDisabled ? "Sent" : item?.buttonText}
              </Button>
            </div>
          </CardContent>
        );

      case "email":
        return (
          <CardContent className="space-y-2 ps-8 pe-8 pb-0 max-md:p-[10px]">
            <div className="h-[114px]">
              <div className="flex justify-center relative w-full mt-6 mb-3">
                <Input
                  placeholder="Enter your email *"
                  className="mt-0 ps-9 h-[56px] rounded-[4px] placeholder:text-placeholder placeholder:font-normal"
                  onChange={(e: any) => setEmail(e?.target?.value)}
                  value={email}
                  type="email"
                />
                <img
                  src={emailIcon}
                  alt=""
                  className="absolute top-4 left-2 font-[400] text-primaryText"
                />
              </div>
              <Label className="text-[14px] font-[400] text-secondaryText">
                If you do not see the email, then please check your spam folder.
              </Label>
            </div>
            <div>
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                disabled={!email || lodaing}
                onClick={sendEmail}
              >
                {item?.buttonText}
              </Button>
            </div>
          </CardContent>
        );

      default:
        break;
    }
  };
  const renderTabContent = (tabs: any) => {
    return tabs?.map((item: any, index: number) => {
      return (
        <TabsContent value={index?.toLocaleString()} className="mt-5">
          <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px]  max-md:h-[340px] max-md:overflow-auto">
            <CardHeader className="p-0 flex flex-row items-center">
              <div className="flex-1">
                <CardTitle className="text-left text-[18px]">
                  {item?.text}
                </CardTitle>
                <CardDescription className="text-left">
                  <div className="mt-2 w-[100%] m-auto max-md:w-[100%] text-[14px]">
                    {item?.additionalText}
                  </div>
                </CardDescription>
              </div>
              <img
                src={item?.image}
                alt="qr_image"
                className="w-[138px] h-[118px] !mt-0"
              />
            </CardHeader>
            {renderContent(item?.contentType, item)}
          </Card>
        </TabsContent>
      );
    });
  };

  const handelLoginResponse = async (result: any) => {
    if (result?.status === 0 || result?.success) {
      localStorage.setItem(
        "uuid",
        JSON.stringify(result?.puid || result?.user?.puid || {})
      );
      const payload = {
        guid: result?.guid || result?.user?.guid,
        uuid: result?.puid || result?.user?.puid,
        checkVC: false,
      };
      const data: any = await getUser(payload);
      if (data?.data?.level === ERROR || data?.data?.statusCode === 404) {
        context.setFailedMessage(AUTHENTICATION_FAILED);
        navigateWithQueryParams("/failed");
        context.setUser({
          ...context.user,
          data,
        });
        localStorage.setItem("user", JSON.stringify(data.user || {}));
        return;
      }
      const user =
        data?.user || JSON.parse(localStorage.getItem("user") ?? "{}");
      if (user._id) {
        const userStatus = getStatusFromUser(data.status);
        if (userStatus === SUCCESS) {
          context.setUser({
            ...context.user,
            ...user,
          });
          localStorage.setItem("user", JSON.stringify(user || {}));
          localStorage.setItem("uuid", JSON.stringify(user?.uuid || {}));
          localStorage.setItem("isPinScreen", "true");
          navigateWithQueryParams("/continuous-authentication-v2");
        } else {
          context.setFailedMessage(ACCOUNT_NOT_APPROVED);
          navigateWithQueryParams("/failure");
        }
      }
    } else {
      context.setFailedMessage(AUTHENTICATION_FAILED);
      navigateWithQueryParams("/failed");
    }
  };

  const onVerifyOtp = async () => {
    const payload = {
      pin: otp,
    };
    const result: { pin: string } | any = await verifyOtp(tokenParams, payload);

    if (result?.success) {
      toast({
        variant: "success",
        description: "Otp verified",
      });
      handelLoginResponse(result);
    } else if (result?.status === 406) {
      toast({
        variant: "destructive",
        description: result?.data?.message,
      });
    }
  };
  return (
    <>
      <Layout>
        <div className="px-10 py-8 h-full max-md:p-[20px]">
          <div className="flex justify-between relative">
            <BackButton />
            <div className="w-full">
              <img src={ComputerIcon} alt="ComputerIcon" className="m-auto" />
            </div>
            <div>{/* {Empty div to adjust space} */}</div>
            <CloseButton onClick={() => navigate(-1)} />
          </div>
          <div className="mt-2">
            <Label className="text-[28px] font-[500] text-primaryText max-md:text-[24px]">
              {pageConfig?.title || "To continue, use your smartphone"}
            </Label>
          </div>
          <div className="text-center overflow-auto h-[480px] mt-0 p-2 max-md:h-[unset]">
            <div className="px-10 max-md:px-0">
              <Label className="text-[14px] font-[400] text-secondaryText">
                {pageConfig?.subheading ||
                  "To avoid problems with the quality of your verification, please continue on mobile and do not close this tab until verification is done."}
              </Label>
            </div>
            <Tabs
              defaultValue={"0"}
              className="w-full"
              value={tab}
              onValueChange={onTabChange}
            >
              <TabsList
                style={{ background: "none" }}
                className={`grid w-full ${
                  isOtp ? "grid-cols-3" : "grid-cols-4"
                }`}
              >
                {List?.map((item: any, index: any) => (
                  <TabsTrigger value={index?.toString()} className="">
                    {item?.title}
                  </TabsTrigger>
                ))}
              </TabsList>
              {pageConfig?.qrCode?.length > 0 ? (
                renderTabContent(pageConfig?.qrCode)
              ) : (
                <>
                  <TabsContent value={"0"} className="mt-5">
                    <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px]  max-md:h-[340px] max-md:overflow-auto">
                      <CardHeader className="p-0 flex flex-row items-center">
                        <div className="flex-1">
                          <CardTitle className="text-left text-[18px]">
                            Scan QR code.
                          </CardTitle>
                          <CardDescription className="text-left">
                            <div className="mt-2 w-[100%] m-auto max-md:w-[100%] text-[14px] bg-[#f9f9f9]">
                              Please scan the QR Code with your mobile phone
                              device to continue your verification.
                            </div>
                          </CardDescription>
                        </div>
                        <img
                          src={qrImage}
                          alt="qr_image"
                          className="w-[138px] h-[118px] !mt-0"
                        />
                      </CardHeader>
                      <CardContent className="space-y-2 mt-4">
                        <div
                          className={`flex  ${
                            isOtp ? "justify-between" : "justify-center"
                          } items-center`}
                        >
                          <div className="bg-[#fff] shadow-2xl p-4 rounded-[20px]">
                            <QRCode
                              size={isOtp ? 100 : 130}
                              value={urlForRedirect}
                            />
                          </div>
                          {isOtp && (
                            <>
                              {" "}
                              <div className="border border-[#ccc] w-[2px] h-[120px] mx-[20px] block" />
                              <div className="w-full">
                                <CardTitle className="text-left text-[18px] mt-[-20px]">
                                  Already have a pin?
                                </CardTitle>
                                <CardTitle className="text-left text-[14px] font-[400] mt-[2px] text-[#4c5667]">
                                  Click here to verify your pin.
                                </CardTitle>
                                <Button
                                  className="text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary w-[70%]"
                                  // onClick={() => onVerifyOtp()}
                                  onClick={() => setTab("2")}
                                >
                                  {"Verify pin"}
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  <TabsContent value={"1"} className="mt-5">
                    <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px] max-md:h-[340px] max-md:overflow-auto">
                      <CardHeader className="p-0 flex flex-row items-center">
                        <div className="flex-1">
                          <CardTitle className="text-left text-[18px]">
                            Copy link
                          </CardTitle>
                          <CardDescription className="text-left">
                            <div className="mt-2 w-[100%] m-auto max-md:w-[100%] text-[14px]">
                              Open this link to complete this verification on a
                              different device.
                            </div>
                          </CardDescription>
                        </div>
                        <img
                          src={linkImage}
                          alt="qr_image"
                          className="w-[138px] h-[118px] !mt-0"
                        />
                      </CardHeader>
                      <CardContent className="space-y-2 ps-8 pe-8 max-md:p-[10px]">
                        <div className="flex justify-center h-[178px]">
                          <div className="relative w-full mt-5">
                            <img
                              src={clipIcon}
                              alt="clipIcon"
                              className="absolute top-3.5 left-2"
                            />
                            <Input
                              placeholder=""
                              className="ps-[2rem] h-[50px] rounded-[8px] pe-[5rem]"
                              defaultValue={urlForRedirect}
                            />
                            <Button
                              onClick={handleCopy}
                              className="absolute right-0 top-1/2 transform -translate-y-1/2 h-[38px] rounded-[24px] w-[113px] bg-primary hover:bg-primary text-white max-md:w-[90px]"
                            >
                              {copied ? 'Copied!' : 'Copy link'}
                            </Button>
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </TabsContent>
                  {isOtp ? (
                    <TabsContent value={"2"} className="mt-5">
                      <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px] max-md:h-[340px] max-md:overflow-auto">
                        <div className="relative">
                          <Label className="text-[28px] font-[500] text-primaryText mb-[20px] block mt-[20px]">
                            Enter your PIN to continue
                          </Label>
                          <div className="h-[230px]">
                            <PinInput
                              length={4}
                              initialValue=""
                              secret
                              focus
                              secretDelay={100}
                              type="numeric"
                              inputMode="number"
                              style={{ padding: "10px" }}
                              inputStyle={{
                                borderColor: "gray",
                                width: isMobile ? "30px" : "50px",
                                height: isMobile ? "30px" : "50px",
                                fontSize: isMobile ? "20px" : "24px",
                                marginLeft: 10,
                                transition: "all .2s",
                              }}
                              inputFocusStyle={{
                                borderColor: "#5283EC",
                                transform: "scale(1.1)",
                              }}
                              onChange={(e) => {
                                // setWrongPin(false);
                              }}
                              onComplete={(value) => {
                                setOtp(value);
                              }}
                              autoSelect={true}
                              regexCriteria={/^[0-9]*$/}
                            />
                            <Button
                              className="text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary w-[70%]"
                              disabled={!otp}
                              onClick={() => onVerifyOtp()}
                            >
                              {pageConfig?.buttonText || "Continue"}
                            </Button>
                          </div>
                          {/* <div
                className={`text-left h-[140px] absolute w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-4 max-md:h-[unset] max-md:relative`}
              ></div> */}
                        </div>
                      </Card>
                    </TabsContent>
                  ) : (
                    <>
                      <TabsContent value={"2"} className="mt-5">
                        <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px] max-md:h-[340px] max-md:overflow-auto">
                          <CardHeader className="p-0 flex flex-row items-center">
                            <div className="flex-1">
                              <CardTitle className="text-left text-[18px]">
                                Receive a link via text message.
                              </CardTitle>
                              <CardDescription className="text-left">
                                <div className="mt-2 w-[100%] m-auto max-md:w-[100%] text-[14px]">
                                  Enter your mobile number and we will send you
                                  a link to complete this verification on a
                                  different device.
                                </div>
                              </CardDescription>
                            </div>
                            <img
                              src={smsImage}
                              alt="qr_image"
                              className="w-[138px] h-[118px] !mt-0"
                            />
                          </CardHeader>
                          <CardContent className="space-y-2 ps-8 pe-8 pb-0 max-md:p-[10px]">
                            <div
                              className={`flex justify-center ${
                                props?.resendCode ? "h-[145px]" : "h-[154px]"
                              } `}
                            >
                              <div className="w-full mt-0">
                                <PhoneInput setValue={setPhone} phone={phone} />
                              </div>
                            </div>
                            {isResendDisabled && smsLimit !== 4 ? (
                              <div className="!mt-[-45px] pb-5">
                                <Label className="text-secondaryText text-[14px]">
                                  Resend Code in{" "}
                                  <span className="text-primary">{timer}s</span>
                                </Label>
                              </div>
                            ) : (
                              ""
                            )}
                            {smsLimit === 4 ? (
                              <div className="!mt-[-55px] pb-[6px] w-[70%] text-center m-auto">
                                <Label className="text-[#FB6E4F] text-[14px] font-[400]">
                                  Usage limit exceeded. Please try other methods
                                  to switch to mobile device.{" "}
                                </Label>
                              </div>
                            ) : (
                              ""
                            )}
                            <div>
                              <Button
                                className={`w-full ${
                                  isResendDisabled
                                    ? "text-[#9ba3b2]"
                                    : "text-white"
                                } bg-primary rounded-[24px] mt-0 hover:opacity-90 hover:bg-primary`}
                                disabled={phone?.length < 10 || lodaing || isResendDisabled}
                                onClick={sendPhone}
                              >
                                {isResendDisabled ? (
                                  <img
                                    src={check}
                                    className="mr-[3px]"
                                    alt=""
                                  />
                                ) : (
                                  ""
                                )}
                                {isResendDisabled ? "Sent" : "Send Link"}
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </TabsContent>
                      <TabsContent value={"3"} className="mt-5">
                        <Card className="border-none bg-[#f8f8f8] rounded-[24px] p-5 pt-2 max-md:p-[10px] max-md:h-[340px] max-md:overflow-auto">
                          <CardHeader className="p-0 flex flex-row items-center">
                            <div className="flex-1">
                              <CardTitle className="text-left text-[18px]">
                                Receive a link via email
                              </CardTitle>
                              <CardDescription className="text-left">
                                <div className="mt-2 w-[100%] m-auto max-md:w-[100%] text-[14px]">
                                  Enter your email and we will send you a link
                                  to complete this verification on a different
                                  device.
                                </div>
                              </CardDescription>
                            </div>
                            <img
                              src={emailImage}
                              alt="qr_image"
                              className="w-[138px] h-[118px] !mt-0"
                            />
                          </CardHeader>
                          <CardContent className="space-y-2 ps-8 pe-8 pb-0 max-md:p-[10px]">
                            <div className="h-[114px]">
                              <div className="flex justify-center relative w-full mt-6 mb-3">
                                <Input
                                  placeholder="Enter your email *"
                                  className="mt-0 ps-9 h-[56px] rounded-[4px] placeholder:text-placeholder placeholder:font-normal"
                                  onChange={(e: any) =>
                                    setEmail(e?.target?.value)
                                  }
                                  value={email}
                                  type="email"
                                />
                                <img
                                  src={emailIcon}
                                  alt=""
                                  className="absolute top-4 left-2 font-[400] text-primaryText"
                                />
                              </div>
                              <Label className="text-[14px] font-[400] text-secondaryText">
                                If you do not see the email, then please check
                                your spam folder.
                              </Label>
                            </div>
                            <div>
                              <Button
                                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                                disabled={!validateEmail(email) || lodaing}
                                onClick={sendEmail}
                              >
                                {lodaing ? 'Sending...' : 'Send Link'}
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </TabsContent>
                    </>
                  )}
                </>
              )}
            </Tabs>
          </div>
          <Label
            className="hover:underline text-primary text-[14px] cursor-pointer mt-[-10px] block text-center mb-5"
            onClick={() => navigate(-1)}
          >
            {pageConfig?.continueText || "Continue on desktop"}
          </Label>
        </div>
      </Layout>
    </>
  );
}

export default SwitchDevice;
