import MainLogo from "assets/login/logo-main.png";
import jack from "assets/V2/airpods.webp";
import { MinusCircle, PlusCircle } from "lucide-react";
import bankCard from "assets/bank-card.png";
import watch from "assets/V2/watch.png";
import { Button } from "components/ui/button";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { useContext } from "react";
import { UserContext } from "context/userContext";
import { useToast } from "components/ui/use-toast";
import { States } from "constant";
import { LoadPageConfiguration } from "utils";
import bloomLogo from "assets/V2/bloomLogo.png";

function CheckoutV2() {
  const { toast } = useToast();
  const context: any = useContext(UserContext);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const credentialDetails = JSON.parse(
    localStorage.getItem("credential") || "{}"
  );
  const cardNumber = "4242 4242 4242 4242";
  const credential = credentialDetails?.credential?.credentialSubject || "";
  const paymentInformation = credentialDetails?.credential
    ? {
        creditCard: cardNumber,
        expiryDate: "12 / 24",
        cvv: 123,
      }
    : {};
  const logoImage = bloomLogo || context?.configuration?.logo;
  return (
    <div
      className={`bg-[#F6F9FC]  py-8 ${
        context.checkoutCompleted ? "h-[100%]" : "h-[100vh]"
      } max-md:h-[100%]`}
    >
      <div className={`flex justify-center`}>
        <img
          src={logoImage || MainLogo}
          alt=""
          width={130}
          data-src={logoImage || MainLogo}
          className={`lazyload`}
        />
      </div>
      <div className="container px-[10rem] max-md:px-[20px]">
        <p className="text-[2.2rem] font-[600] border-b mb-[20px] pb-[15px] border-[#0000001f] border-solid max-md:text-[20px] max-md:mt-[20px]">
          Shopping Cart
        </p>
        <div className="flex justify-between max-md:flex-col">
          {context.checkoutCompleted ? (
            <div className="w-[65%] max-md:w-full">
              <div className="md:col-span-8 xs:col-span-12">
                <div className="m-1">
                  <div className="p-2 bg-white border border-gray-200 rounded">
                    <h3 className="text-lg font-semibold">Summary (2 items)</h3>
                  </div>
                </div>
                <div className="m-1">
                  <div className="p-2 bg-white border border-gray-200 rounded">
                    <h3 className="text-lg font-semibold">
                      1. Select a delivery address
                    </h3>
                    <div className="mt-4 space-y-4">
                      <div className="flex items-center space-x-4">
                        <input
                          className="w-7/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="text"
                          defaultValue={credential?.firstname || ""}
                          placeholder="First Name"
                          name="firstname"
                          required
                        />
                        <input
                          className="w-5/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="text"
                          defaultValue={credential?.lastname || ""}
                          placeholder="Last Name"
                          name="lastname"
                          required
                        />
                      </div>
                      <input
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        type="text"
                        defaultValue={credential?.address1 || ""}
                        placeholder="Your home address (Street address 1)"
                        name="addressLine1"
                        required
                      />
                      <div className="flex items-center space-x-4">
                        <input
                          className="w-7/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="text"
                          defaultValue={credential?.addressLine2 || ""}
                          placeholder="Apt, Unit"
                          name="addressLine2"
                        />
                        <input
                          className="w-5/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="text"
                          defaultValue={credential?.zip || ""}
                          placeholder="ZIP Code"
                          name="zipCode"
                          required
                        />
                      </div>
                      <div className="flex items-center space-x-4">
                        <input
                          className="w-7/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="text"
                          defaultValue={credential?.city || ""}
                          placeholder="Your city"
                          name="city"
                          required
                        />
                        <select
                          className="w-5/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          defaultValue={credential?.state || "default"}
                          name="state"
                          required
                        >
                          {!credential?.state && (
                            <option disabled value={"default"}>
                              Select your state
                            </option>
                          )}
                          {States.map((state) => (
                            <option
                              key={state.abbreviation}
                              value={state.abbreviation}
                            >
                              {state.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <input
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        type="text"
                        defaultValue={credential?.email || ""}
                        placeholder="Email"
                        name="email"
                        required
                      />
                      <input
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        type="text"
                        defaultValue={credential?.phone || ""}
                        placeholder="Phone Number"
                        name="phoneNo"
                        required
                      />
                      <div className="flex items-center space-x-4">
                        <label className="text-sm font-semibold">
                          Have you lived at this address for 6 months or more?
                        </label>
                        <div>
                          <input
                            className="mr-2"
                            type="radio"
                            value="yes"
                            defaultChecked
                            name="radio-buttons-group"
                          />
                          <label className="mr-4">Yes</label>
                          <input
                            className="mr-2"
                            type="radio"
                            value="no"
                            name="radio-buttons-group"
                          />
                          <label>No</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="m-1">
                  <div className="p-2 bg-white border border-gray-200 rounded">
                    <h3 className="text-lg font-semibold">2. Payment Method</h3>
                    <div className="mt-4 space-y-4">
                      <input
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        type="tel"
                        defaultValue={paymentInformation?.creditCard || ""}
                        placeholder="Credit card number"
                        name="payment.cardnumber"
                      />
                      <div className="flex space-x-4">
                        <input
                          className="w-6/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="tel"
                          defaultValue={paymentInformation?.expiryDate || ""}
                          placeholder="Expiry date"
                          name="payment.expiry"
                        />
                        <input
                          className="w-6/12 p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                          type="tel"
                          defaultValue={paymentInformation?.cvv || ""}
                          placeholder="CVV"
                          name="payment.cvv"
                        />
                      </div>
                      <input
                        className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-500"
                        type="text"
                        defaultValue={`${credential?.firstname || ""} ${
                          credential?.lastname || ""
                        }`}
                        placeholder="Name on Card"
                        name="payment.accountHolderName"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="w-[65%] max-md:w-full">
              <div className="w-full block mb-[15px] p-6 bg-white border border-gray-200 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="mb-2 text-[25px] font-[400] tracking-tight text-gray-900 dark:text-white">
                  Summary (2 items)
                </h5>
                <div className="flex mt-5 max-md:flex-col">
                  <img
                    src={jack}
                    alt=""
                    className="w-[100px] mr-5 max-md:m-auto object-contain"
                  />
                  <div>
                    <p className="font-[500] text-[17px]">
                      Apple AirPods (3rd generation) Bluetooth (White, True
                      Wireless)
                    </p>
                    <span className="text-[16px] text-[#767676]">
                      Price: $200
                    </span>
                    <div className="flex items-center justify-between mt-2">
                      <div className="flex items-center justify-between ">
                        <MinusCircle className="text-primary cursor-pointer" />
                        <div className="border border-primary text-primary p-1.5 text-center rounded-[5px] w-[60px] mx-4">
                          1
                        </div>
                        <PlusCircle className="text-primary cursor-pointer" />
                      </div>
                      <p className="text-[#d23f57] cursor-pointer hover:underline">
                        Remove
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full block p-6 bg-white border border-gray-200 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <div className="flex mt-0 max-md:flex-col">
                  <img
                    src={watch}
                    alt=""
                    className="w-[100px] h-[121px] mr-5 max-md:m-auto  object-contain"
                  />
                  <div>
                    <p className="font-[500] text-[17px]">
                    Samsung Galaxy Watch 6 Classic (46mm) - Black
                    </p>
                    <span className="text-[16px] text-[#767676]">
                      Price: $200
                    </span>
                    <div className="flex items-center justify-between mt-2">
                      <div className="flex items-center justify-between ">
                        <MinusCircle className="text-primary cursor-pointer" />
                        <div className="border border-primary text-primary p-1.5 text-center rounded-[5px] w-[60px] mx-4">
                          1
                        </div>
                        <PlusCircle className="text-primary cursor-pointer" />
                      </div>
                      <p className="text-[#d23f57] cursor-pointer hover:underline">
                        Remove
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-[#0000001f] h-[1px] w-full mt-[20px]" />
              <p className="text-[22px] font-[600] mb-[0px] pb-[15px] mt-[20px]">
                Next Steps
              </p>
              <div className="w-full block mb-[15px] p-4 bg-white border border-gray-200 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="text-[20px] font-[400] tracking-tight text-gray-900 dark:text-white">
                  1. Select a delivery address
                </h5>
              </div>
              <div className="w-full block mb-[15px] p-4 bg-white border border-gray-200 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
                <h5 className="text-[20px] font-[400] tracking-tight text-gray-900 dark:text-white">
                  2. Payment Method
                </h5>
              </div>
            </div>
          )}

          <div className="w-[33%] max-md:w-full">
            <div className="w-full block mb-[15px] p-6 bg-white border border-gray-200 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div>
                <div className="flex items-center">
                  <img
                    src={bankCard}
                    alt=""
                    className="w-[100px] h-[50px] mr-2"
                  />
                  <p className="font-[400] text-[14px] text-primary underline cursor-pointer">
                    Apply for our Universal Rewards Credit Card
                  </p>
                </div>
                <p className="font-[400] text-[14px] text-[#767676] underline cursor-pointer mt-[10px]">
                  Earn 2% Cash Back on your purchases at participating
                  retailers. Exclusive offer for members.
                </p>
                <Button
                  className="w-full text-white bg-primary rounded-[5px] mt-4 hover:opacity-90 hover:bg-primary uppercase"
                  disabled={true}
                >
                  Apply today
                </Button>
              </div>
            </div>
            <div className="w-full block mb-[15px] p-6 bg-white border border-[#008000] border-2 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div>
                <p className="font-[500] text-[17px] text-[#008000]">
                  Standard: Shipping & Handling
                </p>
                <p className="font-[400] text-[16px] text-[#767676] mt-[10px]">
                  Estimated Delivery
                </p>
                <p className="font-[500] text-[18px] text-[#008000]">
                  Thursday, November 9, 2024
                </p>
              </div>
            </div>
            <div className="w-full block mb-[15px] p-6 bg-white border border-gray-200 rounded-[5px] shadow dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
              <div className="flex justify-between mb-1 items-center">
                <p className="font-[200] text-[17px] text-[#000]">Total</p>
                <p className="font-[500] text-[17px] text-[#000]">$400.00</p>
              </div>
              <div className="flex justify-between mb-1 items-center">
                <p className="font-[200] text-[17px] text-[#000]">
                  Shipping and Handling
                </p>
                <p className="font-[500] text-[17px] text-[#000]">$0.00</p>
              </div>
              <div className="flex justify-between mb-1 items-center">
                <p className="font-[200] text-[17px] text-[#000]">Discount</p>
                <p className="font-[500] text-[17px] text-[#000]">$0.00</p>
              </div>
              <div className="bg-[#0000001f] h-[1px] w-full mt-[10px] mb-[10px]" />
              <div className="flex justify-between mb-1 items-center">
                <p className="font-[200] text-[20px] text-[#000]">
                  Estimated Total
                </p>
                <p className="font-[500] text-[17px] text-[#000]">$400.00</p>
              </div>
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
                onClick={() => {
                  if (context.checkoutCompleted) {
                    toast({
                      variant: "success",
                      description: "Purchase Successful.",
                    });
                    LoadPageConfiguration(context, "/");
                    context.setCheckoutCompleted(false);
                    navigateWithQueryParams("/", { token: undefined });
                  } else {
                    context.setLoginOption("prove-checkout-end");
                    navigateWithQueryParams("/login-v2");
                  }
                }}
              >
                {context.checkoutCompleted ? "Confirm" : "Click to pay"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckoutV2;
