import userLogo from "assets/selfie.svg";
const config = {
  clientConfig: {
    successUrl: window.location.origin,
    failureUrl: window.location.origin,
    type: "IDENTITY",
    productGroupId: "priflo",
  },

  enrollConfig: {
    successUrl: "https://xcm.cvsauth.com",
    failureUrl: "https://xcm.cvsauth.com",
    type: "IDENTITY",
    productGroupId: "priflo",
    redirectURL: "https://kantara.privateid.com/",
    callback: {
      url: "https://www.google.com",
      headers: {},
    },
  },
  verifyConfig: {
    successUrl: "https://xcm.cvsauth.com",
    failureUrl: "https://xcm.cvsauth.com",
    type: "VERIFY",
    productGroupId: "priflo",
    redirectURL: "https://www.google.com",
    callback: {
      url: "https://www.google.com",
      headers: {},
    },
  },
};

export const uberConfig = {
  primaryColor: "#000000",
  createdAt: "2024-05-28T10:48:31.908Z",
  updatedAt: "2024-07-03T06:10:03.447Z",
  publishedAt: "2024-07-03T05:42:40.136Z",
  environment: "devel",
  name: "ultra-v2",
  secondaryColor: null,
  projectId: "ultra-v2",
  locale: "en",
  apiKey: "0000000000000000test",
  productGroupId: "ultra",
  cryptonetsUrl: null,
  orchestrationUrl: null,
  uploadToServer: false,
  env: {
    REACT_APP_API_KEY: "0000000000000000test",
    REACT_APP_API_URL: "https://api.orchestration.devel.privateid.com/oidc",
    GENERATE_SOURCEMAP: false,
    REACT_APP_CLIENT_ID: "erQegVpq2Nw1buRmxTfNY",
    SKIP_PREFLIGHT_CHECK: false,
    REACT_APP_WASM_MODULE: "face_mask",
    REACT_APP_API_URL_WASM: "https://api.develv2.cryptonets.ai/node",
    REACT_APP_REDIRECT_URL: "https://meta.dev.privateid.co",
    REACT_APP_CLIENT_SECRET:
      "RR6qas9HmQSZSbb670yKMN0E_fNPluks7mUn8B8yclnGhU79JHA4WhxK5KPedOG9GhiGU8wK_ocsN1m7ebgopA",
    REACT_APP_OIDC_AUTH_URL: "https://oidc.devel.privateid.com/auth",
    REACT_APP_OIDC_AUTHORITY:
      "https://oidc.devel.privateid.com/.well-known/openid-configuration",
    REACT_APP_OIDC_TOKEN_URL: "https://oidc.devel.privateid.com/token",
    REACT_APP_PRODUCT_GROUP_ID: "metro",
    REACT_APP_API_ORCHESTRATION:
      "https://api.orchestration.devel.privateid.com",
    REACT_APP_OIDC_REDIRECT_URL: "https://meta.dev.privateid.co/callback",
    REACT_APP_AUTH_PRODUCT_GROUP_ID: "test102",
    REACT_APP_ORCHESTRATION_API_URL:
      "https://api.orchestration.devel.privateid.com",
  },
  consumerButtons: false,
  bannerWidth: "300px",
  successCall: true,
  ciba: null,
  liveness: null,
  byPassUser: null,
  redirectUrl: null,
  bgColor: null,
  logo: userLogo,
  bannerImage:
    "https://privateid-cms.s3.us-east-1.amazonaws.com/large_uber_Banner_af41384f91.png",
  appPages: [
    {
      id: 178,
      __component: "page.login-options",
      title: null,
      subheading: null,
      enable: true,
      currentRoute: "/",
      buttonText: null,
      additionalText: null,
      accountText: null,
      registerText: null,
    },
    {
      id: 1015,
      __component: "page.enroll",
      currentRoute: "/landing",
      status: null,
      enable: true,
      type: null,
      havingText: "Having problems?",
      switchText: "Switch to mobile",
      title: "To continue, use your smartphone",
    },
    {
      id: 174,
      __component: "page.user-consent",
      title: "User consent",
      buttonText: "Continue",
      enable: true,
      currentRoute: "/user-consent",
      consentBody: [
        {
          type: "paragraph",
          children: [
            {
              bold: true,
              text: "MODEL CLAUSE FOR DEMO ENVIRONMENT ID SCAN:",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "* You certify that you reside in one of the following states: Alaska, Arkansas, Colorado, Connecticut, Delaware, Florida, Georgia, Idaho, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nevada, New York, North Dakota, Ohio, Oklahoma, Pennsylvania, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Washington, Washington DC, West Virginia, Wisconsin, or Wyoming.",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              bold: true,
              text: "MODEL CLAUSES FOR OPERATIONAL ENVIRONMENT:",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
              type: "text",
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "If you choose to capture your selfie using the Private Identity application, your selfie will be processed by a neural network running in your browser that compares your face to your Photo ID in a few milliseconds. This process is fast and accurate, and does not keep or share your selfie.\n",
                  type: "text",
                },
              ],
            },
            {
              type: "list-item",
              children: [
                {
                  text: "The biometric data is only captured and processed on your device, and is deleted within one second. Private Identity never transmits, receives, stores, manages or discloses your biometric data.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "Only encrypted data is sent to the Private Identity servers. Private Identity then confirms with third-party partners whether or not you have reached the identity assurance threshold required to create an account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "This app is certified compliant with the IEEE 2410-2021 Standard for Biometric Privacy. No biometric template is created, processed, stored, transmitted, or received.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I acknowledge I am over 21 years of age, all information I provided is accurate, and I am prohibited from allowing any other person to access or use my verified Private Identity account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I agree to register for an account using my face images and my US state issued driving license or identity card. Private Identity, and our third-party enrollment and identity proofing service providers IDEMIA and CentralAMS, may share, use and maintain the images and information you provide, and the information on file with other third-party service providers to further verify your identity, to protect against or prevent actual or potential fraud or unauthorized use of the Service, and to establish, maintain and authenticate your verified digital identity, for the duration of our business relationship.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I agree to receive email and SMS messages for the purpose of registering and maintaining an account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I have read and accepted the Private Identity LLC ",
                  type: "text",
                },
                {
                  url: "https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use",
                  type: "link",
                  children: [
                    {
                      text: "Terms of Use",
                      type: "text",
                    },
                  ],
                },
                {
                  text: " and ",
                  type: "text",
                },
                {
                  url: "https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy",
                  type: "link",
                  children: [
                    {
                      text: "Privacy Policy",
                      type: "text",
                    },
                  ],
                },
                {
                  text: ", CentralAMS ",
                  type: "text",
                },
                {
                  url: "https://www.centralams.com/terms-of-use/",
                  type: "link",
                  children: [
                    {
                      text: "Terms of Use",
                      type: "text",
                    },
                  ],
                },
                {
                  text: " and ",
                  type: "text",
                },
                {
                  url: "https://www.centralams.com/privacy-policy/",
                  type: "link",
                  children: [
                    {
                      text: "Privacy Policy",
                      type: "text",
                    },
                  ],
                },
                {
                  text: ", and the IDEMIA ",
                  type: "text",
                },
                {
                  url: "https://na.idemia.com/terms/",
                  type: "link",
                  children: [
                    {
                      text: "Terms of Use",
                      type: "text",
                    },
                  ],
                },
                {
                  text: " and ",
                  type: "text",
                },
                {
                  url: "https://na.idemia.com/privacy/",
                  type: "link",
                  children: [
                    {
                      text: "Privacy Policy",
                      type: "text",
                    },
                  ],
                },
                {
                  text: "",
                  type: "text",
                },
              ],
            },
          ],
        },
      ],
      acknowledgeText:
        "I acknowledge that I have read, understood and agree to Private ID’s Terms and Privacy Policy",
      logo: null,
    },
    {
      id: 174,
      __component: "page.user-info",
      title: "Personal details ",
      subheading: "Please enter the required information",
      buttonText: "Next",
      currentRoute: "/user-info",
      enable: true,
      sendMsg: false,
      logo: null,
      inputs: [
        {
          id: 1034,
          name: "phone",
          placeholder: "Enter your phone number *",
          type: "phone",
          enable: true,
          icon: null,
        },
      ],
    },
    {
      id: 684,
      __component: "page.enroll-intro",
      title: "Take a selfie to register",
      subheading: "No images will leave your device.",
      currentRoute: "/face-scan-intro",
      buttonText: "Start",
      enable: true,
      type: null,
      havingText: " Having problems?",
      switchText: "Switch to Phone",
      faceInstructions: [
        {
          id: 1236,
          heading: "Take off glasses",
          text: "Ensure nothing covers your face",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/no_hat_965e7772bf.svg",
        },
        {
          id: 1235,
          heading: "Ensure good lighting",
          text: "Your face isn't backlit by a light source",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/sun_b88caf5166.svg",
        },
        {
          id: 1234,
          heading: "On-Device Processing",
          text: "No facial image leaves this device",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/no_Face_9dae1a634e.png",
        },
      ],
    },
    {
      id: 1015,
      __component: "page.enroll",
      currentRoute: "/face-scan",
      status: null,
      enable: true,
      type: null,
      havingText: "Having problems?",
      switchText: "Switch to mobile",
      title: "No images will leave this device",
    },
    {
      id: 174,
      __component: "page.document-selection",
      title: "Verify your identity document",
      subheading: "Please choose your Photo ID",
      currentRoute: "/doc-selection",
      buttonText: null,
      enable: true,
      selectionOptions: [
        {
          id: 361,
          label: "Driver’s license or National/State ID",
          value: "us-identification",
          image: null,
        },
      ],
    },

    {
      id: 1016,
      __component: "page.enroll",
      currentRoute: "/waiting",
      status: "Please wait a sec, we’re registering your identity..",
      enable: true,
      type: null,
      havingText: null,
      switchText: null,
      title: null,
    },
    {
      id: 649,
      __component: "page.success",
      // message: "Your account is registered!",
      buttonText: "Home",
      currentRoute: "/success",
      enable: true,
      type: null,
    },
    {
      id: 650,
      __component: "page.success",
      message: "Account Creation Failed",
      buttonText: null,
      currentRoute: "/failure",
      enable: true,
      type: null,
      secondaryButton: "Return to homepage",
    },
    {
      id: 1020,
      __component: "page.enroll",
      currentRoute: "/face-login",
      status: null,
      enable: true,
      type: "login",
      havingText: null,
      switchText: null,
      title: "Biometrics never leave this device",
    },
    {
      id: 1018,
      __component: "page.enroll",
      currentRoute: "/login-dl",
      status: null,
      enable: true,
      type: "login",
      havingText: null,
      switchText: null,
      title: "Images never leave this device",
    },
    {
      id: 652,
      __component: "page.success",
      message: "Authentication failed!",
      buttonText: "Back to home",
      currentRoute: "/failed",
      enable: true,
      type: "login",
      secondaryButton: null,
    },
    {
      id: 651,
      __component: "page.success",
      message: "Authentication successful!",
      buttonText: "Continue",
      currentRoute: "/success",
      enable: true,
      type: "login",
      secondaryButton: "Back to home",
    },

    {
      id: 358,
      __component: "page.vc-proof",
      title: "Verifiable Credentials",
      subheading: "Please select the personal details you want to share.",
      enable: true,
      currentRoute: "/vc-proof",
      buttonText: "Share personal details",
      type: "login",
      secondaryButton: null,
    },
    {
      id: 145,
      __component: "page.switch-device",
      title: "To continue, use your smartphone",
      currentRoute: "/switch-device",
      enable: true,
      subheading:
        "To avoid problems with the quality of your verification, please continue on mobile and do not close this tab until verification is done.",
      continueText: null,
      qrCode: [],
    },
    {
      id: 683,
      __component: "page.enroll-intro",
      title: "Scan back of ID card",
      subheading: "Scan the back of your ID card in a well-lit area",
      currentRoute: "/drivers-licence-back-intro",
      buttonText: "Start",
      enable: true,
      type: "login",
      havingText: "Having problems?",
      switchText: "Switch to mobile",
      faceInstructions: [
        {
          id: 1241,
          heading: "Visible 4 corners",
          text: "Visible 4 corners",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/visible_Corner_64c3193794.svg",
        },
        {
          id: 1242,
          heading: "Not Cut",
          text: "Not Cut",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/Not_Cut_30c80ef188.svg",
        },
        {
          id: 1243,
          heading: "Uncluttered background",
          text: "Uncluttered background",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/un_Cluttered_BG_d72deddfbd.svg",
        },
        {
          id: 1244,
          heading: "Not reflective",
          text: "Not reflective",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/reflective_c3ff78ac24.svg",
        },
      ],
    },
  ],
  footer: [],
};

export default config;