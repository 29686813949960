import { loadPrivIdModule as loadOidc } from "@privateid/ultra-web-sdk-alpha";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";

let isLoading = false;

let wasmReloadRetries = 0;

const useWasm = (
  sessionToken = "",
  apiUrl = "",
  publicKey = "",
  timeout = 0,
  onModelLoadingFail = () => {}
) => {
  // Initialize the state
  const [ready, setReady] = useState(false);
  const [wasmStatus, setWasmStatus] = useState<any>({ isChecking: true });
  const context = useContext(UserContext);
  
  const wasmFailureCallbackHandler = async (result: any) => {
    console.log("wasmFailure Handler!!: ", result);
    if (result === 1 || result === 2) {
      console.log("Loading models.");
      await loadOidc({
        api_url: {
          collections: {
            default: {
              named_urls: {
                base_url: apiUrl,
              } as any,
            },
          },
        },
        sessionToken,
        publicKey,
        timeout,
        useCdn: false,
        wasmFailureCallback: wasmFailureCallbackHandler,
        shouldRegenerateSession: true,
      } as any);
      wasmReloadRetries++;
      console.log("RETRY WASM LOAD:", wasmReloadRetries);
    }

    if (wasmReloadRetries >= 5) {
      //alert("WASM LAODING FAIL, Returning to homepage");
      window.location.href = `/`;
      wasmReloadRetries = 0;
      return;
    }
  };

  const { isWasmLoaded, setIsWasmLoaded } = context;
  const init = async () => {
    console.log("init start");
    console.log({
      api_url: {
        collections: {
          default: {
            named_urls: {
              base_url: apiUrl,
            } as any,
          },
        },
      },
      sessionToken,
      publicKey,
      timeout,
      useCdn: false,
      wasmFailureCallback: wasmFailureCallbackHandler,
    }, "useCdn");
    
    const res = await loadOidc({
      api_url: {
        collections: {
          default: {
            named_urls: {
              base_url: apiUrl,
            } as any,
          },
        },
      },
      sessionToken,
      publicKey,
      timeout,
      useCdn: false,
      wasmFailureCallback: wasmFailureCallbackHandler,
    });
    console.log("OIDC WASM RES:", res);

    if (res.support) {
      setReady(true);
      setWasmStatus({ isChecking: false, ...res });
      setIsWasmLoaded(true);
    } else {
      setReady(false);
      setWasmStatus({ isChecking: false, ...res });
    }
  };

  const reloadWasm = () => {
    setReady(false);
    setIsWasmLoaded(false);
    setWasmStatus({ isChecking: true });
    init();
  };

  useEffect(() => {
    if (ready) return;
    if (!isWasmLoaded && !isLoading) {
      init();
      isLoading = true;
    }
    if (isWasmLoaded) {
      console.log("====> WASM LOADED");
      setReady(true);
      setWasmStatus({ isChecking: false, support: true });
    }
  }, [isWasmLoaded, ready, wasmStatus]);

  return { ready, wasmStatus, reloadWasm };
};

export default useWasm;